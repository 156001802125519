import React, { Component } from 'react'
import translate from '../../../../modules/translate'
import errorLog from '../../../../modules/error-log'
import StolLogo from '../../../../images/StolLogo'

const textStyles = {
  fontFamily: '"Fira Sans",sans-serif',
  fontSize: '20px',
  padding: '60px 60px 100px',
  textAlign: 'center',
  backgroundColor: '#EEE',
  minHeight: '100vh',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'rgba(0, 0, 0, .8)',
}
const logoStyles = {
  display: 'block',
  width: '180px',
  marginBottom: '3em',
}

class AppError extends Component {
  shouldComponentUpdate() {
    return false
  }
  render() {
    const { logError, logInfo, ...props } = this.props
    errorLog({ error: logError, info: logInfo, type: 'app' })
    return (
      <div style={textStyles} {...props}>
        <StolLogo alt="STOL" style={logoStyles} />
        <strong>{translate('error_app_error_title')}</strong>
        {translate('error_app_error_message')}
      </div>
    )
  }
}

AppError.propTypes = {
  logError: errorLog.propTypes.error,
  logInfo: errorLog.propTypes.info,
}

AppError.defaultProps = {
  logError: 'AppError',
}

export default AppError
