import hasItems from '../../modules/has-items'

class RouteQuery {
  constructor({ useLocationPathname, customApiPath, version, modifyResponse, ...props }) {
    if (useLocationPathname) {
      this.useLocationPathname = useLocationPathname
    }
    if (customApiPath) {
      this.customApiPath = customApiPath
    }
    if (modifyResponse) {
      this.modifyResponse = modifyResponse
    }

    if (version || hasItems(props)) {
      this.fetchVariables = {
        ...props,
      }
      if (version) {
        this.fetchVariables.version = version
      }
    }
  }
}

export default RouteQuery
