import isServerSide from '../../../../../../modules/is-server-side'

const onCheckoutStateChange = () => {
  if (!isServerSide()) {
    window.tp.push([
      'addHandler',
      'checkoutStateChange',
      function (stateView) {
        const user = window.localStorage.getItem('user')
        if (user) {
          const isPremiumSelected = stateView.stateName === 'state2'
          const hasSubscription = JSON.parse(user).hasSubscription
          if (isPremiumSelected && hasSubscription) {
            window.tp.offer.close()
          }
        }
      },
    ])
  }
}

export default onCheckoutStateChange
