// you should use this module if you need to define static text. Add the static text with a unique key to src/config/translations/

import React from 'react'
import Link from '../../components/Link'
import { unique as uniqueHash } from 'shorthash'
import Icon from '../../components/Icon'
import reactReplace from '../react-replace'

export default (text, args) => {
  return parseHTML(text, args)
}

function parseHTML(text, args) {
  const tokens = tokenize(text)
  const replacesTokens = replaceTokens(tokens, args)
  const htmlArray = toHTML(replacesTokens, uniqueHash(text))
  if (htmlArray.length === 1) {
    return htmlArray[0]
  }
  return htmlArray
}

function toHTML(tokens, keyPrefix) {
  return tokens.map((token, index) => {
    const key = keyPrefix + index + token.type
    switch (token.type) {
      case 'bold': {
        return <strong key={key}>{toHTML(token.children, key)}</strong>
      }
      case 'link': {
        return (
          <Link autoExternalLinkIcon key={key} inherit {...token.props}>
            {toHTML(token.children, key)}
          </Link>
        )
      }
      case 'icon': {
        return <Icon key={key} icon={token.name} />
      }
      default: {
        return token.content
      }
    }
  })
}

function tokenize(text) {
  function tokenText(content = '') {
    return { type: 'text', content: content }
  }

  function addText() {
    if (currentText.content.length) {
      if (!parent) {
        ret.push(currentText)
      } else {
        parent.children.push(currentText)
      }
      currentText = tokenText()
    }
  }

  const linkRegexp = /\[(.*)]\((.*)\)/
  let index = 0
  let ret = []
  let currentText = tokenText()
  let parent

  function addToCurrentText(char) {
    currentText.content += char
  }

  while (index < text.length) {
    const char = text.charAt(index)
    switch (char) {
      case '*':
        if (text.charAt(index + 1) === '*') {
          addText()
          if (parent) {
            ret.push(parent)
            parent = undefined
          } else {
            parent = { type: 'bold', children: [] }
          }
          index++
        } else {
          addToCurrentText(char)
        }
        break
      case ':':
        const match = text.substring(index).match(/:([^: [\]{}()]+):/)
        if (match && match.index === 0) {
          addText()
          const icon = { type: 'icon', name: match[1] }
          index += match[0].length - 1
          if (parent) {
            parent.children.push(icon)
            break
          }
          ret.push(icon)
        } else {
          addToCurrentText(char)
        }
        break
      case '[': {
        const match = text.substring(index).match(linkRegexp)
        if (match && match.index === 0) {
          addText()
          const link = { type: 'link', children: [tokenText(match[1])], props: { to: match[2] } }
          index += match[0].length - 1
          if (parent) {
            parent.children.push(link)
            break
          }
          ret.push(link)
        } else {
          addToCurrentText(char)
        }
        break
      }
      default: {
        addToCurrentText(char)
      }
    }
    index++
  }
  addText()
  return ret
}

function replaceTokens(tokens, args) {
  return tokens.map((token) => {
    if ('content' in token) {
      token['content'] = reactReplace(token['content'], args)
    }
    if ('children' in token) {
      token['children'] = replaceTokens(token['children'], args)
    }
    if ('props' in token) {
      Object.keys(token['props']).forEach((key) => {
        token['props'][key] = reactReplace(token['props'][key], args)
      })
    }
    return token
  })
}
