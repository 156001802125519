import React from 'react'

function Bookmark(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M17.667,3H7.111a2.108,2.108,0,0,0-2.1,2.111L5,22l7.389-3.167L19.778,22V5.111A2.117,2.117,0,0,0,17.667,3Zm0,15.833-5.278-2.3-5.278,2.3V5.111H17.667Z" />
    </svg>
  )
}

export default Bookmark
