const options = require('../../config/options')
const envApiPrefix = () => {
  const envApiPrefix = process && process.env && process.env.APIPrefix
  const isEnvApiPrefix = envApiPrefix && envApiPrefix.includes(options.Api.serverSide)
  if (isEnvApiPrefix) {
    return envApiPrefix
  }
  return false
}

module.exports = envApiPrefix
