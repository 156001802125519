import React from 'react'

function SvgHome(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M10 20.5H5v-8H2l10-9 10 9h-3v8h-5v-6h-4v6z" />
    </svg>
  )
}

export default SvgHome
