import apiConnector from '../api-connector'
import isServerSide from '../is-server-side'

const handlerFetch = async ({ queryKey: [_key, { version, userAgent, external } = {}, location], signal }) => {
  return await apiConnector({
    version,
    userAgent,
    location: location || _key,
    signal,
    external,
    allowAbortSignal: !isServerSide(),
  })
}

export default handlerFetch
