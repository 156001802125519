import React from 'react'

function SvgPlayArrow(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M8 5l11 7-11 7z" />
    </svg>
  )
}

export default SvgPlayArrow
