import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Color from 'color'
import { WidgetConsumer } from '../../bundles/components/WidgetContext'

const getBackgroundSecondary = ({ cinemaMode, theme }) => {
  if (!cinemaMode) return
  const background = 'background: '
  if (typeof cinemaMode === 'string') {
    return background + theme.color[cinemaMode].backgroundSecondary
  }
  if (cinemaMode.backgroundSecondary) {
    if (cinemaMode.backgroundPrimary && cinemaMode.backgroundSecondary === 'auto') {
      return background + Color(cinemaMode.backgroundPrimary).darken(0.5)
    }
    return background + cinemaMode.backgroundSecondary
  }
  return background + theme.color.backgroundContent
}

const ContainerFull = styled.div.attrs(({ noMobileGapEdge, noGapEdge, ...props }) => {
  props.className = props.className ? props.className + ' containerFull' : 'containerFull'

  return {
    noMobileGapEdge: true,
    //noGapEdge: true,
    ...props,
  }
})`
  position: relative;
  z-index: 0;
  padding-left: ${(props) => (props.noGapEdge ? '0' : props.theme.default.sizes.gapEdge)};
  padding-right: ${(props) => (props.noGapEdge ? '0' : props.theme.default.sizes.gapEdge)};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-left: ${(props) => (props.noGapEdge ? '0' : props.theme.tablet.sizes.gapEdge)};
    padding-right: ${(props) => (props.noGapEdge ? '0' : props.theme.tablet.sizes.gapEdge)};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-left: ${(props) => (props.noGapEdge ? '0' : props.theme.mobile.sizes.gapEdge)};
    padding-right: ${(props) => (props.noGapEdge ? '0' : props.theme.mobile.sizes.gapEdge)};
  }
  ${(props) =>
    props.noMobileGapEdge &&
    css`
      @media (max-width: 450px) {
        padding-left: 0;
        padding-right: 0;
      }
    `}

  ${(props) =>
    // .hasWallpaper coming from Google Ad Manager (Wallpaper Creative)
    props.cinemaMode &&
    css`
      .mainContainer:not(.hasWallpaper) &&:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -50vw;
        width: calc(100vw - 17px);
        bottom: 0;
        background-color: ${(props) =>
          props.cinemaMode &&
          (typeof props.cinemaMode === 'string'
            ? props.theme.color[props.cinemaMode].backgroundPrimary
            : props.cinemaMode.backgroundPrimary)};
        z-index: -2;
        height: ${(props) => (props.height ? props.height : 'auto')};

        @media (max-width: 1680px) {
          ${WidgetConsumer._currentValue && 'width: calc(100vw + 60px);'}
          left: calc(50% + ((300px - 160px) / 2));
        }

        @media (max-width: 1540px) {
          left: calc(50% + (300px / 2));
        }

        @media (max-width: 1380px) {
          left: 50%;
        }
        @media (max-width: 600px) {
          ${WidgetConsumer._currentValue && 'width: 100vw;'}
        }
        @media (max-width: 1080px) {
          display: none;
        }
      }

      &:before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        ${(props) => getBackgroundSecondary(props)};
      }
    `};
`

ContainerFull.propTypes = {
  noGapEdge: PropTypes.bool,
  noMobileGapEdge: PropTypes.bool,
  children: PropTypes.node,
  cinemaMode: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  height: PropTypes.string,
}

export default ContainerFull
