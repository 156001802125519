import isServerSide from '../../../../modules/is-server-side'

const isUserValidPiano = () => {
  if (!isServerSide() && window.tp && window.tp.pianoId) {
    return window.tp.pianoId.isUserValid()
  }
  return undefined
}

export default isUserValidPiano
