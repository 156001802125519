//a module to check if the React App/React Bundle is running in the App.

import checkForValueInUserAgent from '../check-for-value-in-useragent'

const DEVICES = {
  ios: 'iphone|ipod|ipad',
  android: 'android',
}

const isStolApp = (device) => {
  let valueToCheck = 'stolapp'

  if (device) {
    valueToCheck = `(?=.*${valueToCheck})(?=.*${device}).+`
  }

  return checkForValueInUserAgent(valueToCheck)
}

export default isStolApp

export { DEVICES }
