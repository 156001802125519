import React from 'react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then((d) => ({
    default: d.ReactQueryDevtools,
  })),
)

const DevToolsQuery = () => (
  <>
    <ReactQueryDevtools initialIsOpen={false} />
    <React.Suspense fallback={null}>
      <ReactQueryDevtoolsProduction initialIsOpen={false} />
    </React.Suspense>
  </>
)

export default DevToolsQuery
