import enlargeFont from './images/EnlargeFont.js'
import facebook from './images/Facebook.js'
import googlePlus from './images/GooglePlus.js'
import instagram from './images/Instagram.js'
import linkedin from './images/Linkedin.js'
import menu from './images/Menu.js'
import print from './images/Print.js'
import reduceFont from './images/ReduceFont.js'
import share from './images/Share.js'
import twitter from './images/Twitter.js'
import externalLink from './images/ExternalLink.js'
import eye from './images/Eye.js'
import closedEye from './images/ClosedEye.js'
import arrowDown from './images/ArrowDown.js'
import arrowUp from './images/ArrowUp.js'
import arrowLeft from './images/ArrowLeft.js'
import arrowRight from './images/ArrowRight.js'
import pause from './images/Pause.js'
import playArrow from './images/PlayArrow.js'
import search from './images/Search.js'
import weather from './images/Weather.js'
import car from './images/Car.js'
import camera from './images/Camera.js'
import person from './images/Person.js'
import gps from './images/Gps.js'
import home from './images/Home.js'
import email from './images/Email.js'
import photoLibrary from './images/PhotoLibrary.js'
import close from './images/Close.js'
import playCircle from './images/PlayCircle.js'
import key from './images/Key.js'
import error from './images/Error.js'
import exit_to_app from './images/ExitToApp.js'
import my_location from './images/MyLocation.js'
import cross from './images/Cross.js'
import create from './images/Create.js'
import paper from './images/Paper.js'
import reader from './images/Reader.js'
import photo from './images/Photo.js'
import bin from './images/Bin.js'
import dolomiten from './images/Dolomiten.js'
import calendar from './images/Calendar.js'
import warning from './images/Warning.js'
import clock from './images/Clock.js'
import whats_app from './images/WhatsApp.js'
import check from './images/Check.js'
import loggedIn from './images/LoggedIn.js'
import splus from './images/Splus'
import backend from './images/Backend'
import article from './images/Article'
import video from './images/Video'
import bookmark from './images/Bookmark'
import stellenanzeigen from './images/Stellenanzeigen'
import mapmark from './images/MapMark'
import notification from './images/Notification'
import phonenumber from './images/Phonenumber'
import account from './images/Account'
import keyboard from './images/KeyBoard'
import config from './images/Config'

const svgIcons = {
  warning,
  calendar,
  dolomiten,
  enlargeFont,
  facebook,
  googlePlus,
  instagram,
  linkedin,
  bin,
  menu,
  print,
  reduceFont,
  share,
  twitter,
  externalLink,
  eye,
  closedEye,
  arrowDown,
  arrowUp,
  arrowLeft,
  arrowRight,
  pause,
  playArrow,
  search,
  weather,
  car,
  camera,
  person,
  gps,
  home,
  email,
  photoLibrary,
  close,
  playCircle,
  key,
  error,
  exit_to_app,
  my_location,
  cross,
  create,
  paper,
  reader,
  photo,
  clock,
  whats_app,
  check,
  loggedIn,
  splus,
  phonenumber,
  backend,
  article,
  video,
  newsticker: clock,
  bookmark,
  stellenanzeigen,
  mapmark,
  notification,
  account,
  keyboard,
  config,
}

export default svgIcons
