import consoleLogger from '../../../../../../modules/console-logger'
import isServerSide from '../../../../../../modules/is-server-side'

const onCheckoutComplete = () => {
  const [consoleLog] = consoleLogger('OnCheckoutComplete')
  if (!isServerSide()) {
    window.tp.push([
      'addHandler',
      'checkoutComplete',
      function () {
        consoleLog('EXECUTE', '', 'ExperienceExecute')
        window.tp.experience.execute()
      },
    ])
  }
}

export default onCheckoutComplete
