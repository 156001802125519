import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import iconKey from './keys/iconKeys'

const Wrapper = styled.i`
  display: inline-block;
  color: ${(props) => (props.inherit ? 'inherit' : props.theme.color.textPrimary)};
  fill: ${(props) => (props.inherit ? 'inherit' : props.theme.color.textPrimary)};
  vertical-align: -0.13em;
`

const svgStyle = css`
  fill: currentColor;
  height: 1em;
  display: block;
`

class Icon extends Component {
  state = {
    iconWidth: 1,
  }
  constructor() {
    super()
    this.wrapper = React.createRef()
  }
  render() {
    const { inherit, icon, ...props } = this.props
    let Svg
    if (iconKey[icon] !== undefined) {
      Svg = styled(iconKey[icon])(svgStyle)
    } else {
      Svg = styled(iconKey['eye'])(svgStyle)
    }
    return (
      <Wrapper ref={this.wrapper} inherit={inherit} {...props}>
        <Svg width={this.state.iconWidth + 'em'} />
      </Wrapper>
    )
  }
}

Icon.propTypes = {
  icon: PropTypes.oneOf(Object.keys(iconKey)).isRequired,
}

Icon.defaultProps = {
  icon: 'eye',
  inherit: true,
}

export default Icon
