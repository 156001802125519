import React from 'react'

function SvgArrowLeft(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M15.7 16.6L14.3 18l-6-6 6-6 1.4 1.4-4.6 4.6z" />
    </svg>
  )
}

export default SvgArrowLeft
