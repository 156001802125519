import React from 'react'
import { Helmet } from 'react-helmet-async'
import onLoginSuccess from './modules/on-login-success'
import onCheckoutStateChange from './modules/on-checkout-state-change'
import onCheckoutComplete from './modules/on-checkout-complete'
import isStolApp from '../../../../modules/is-stolapp'
import setCustomVariablesPiano from '../../modules/set-custom-variables-piano'
import isServerSide from '../../../../modules/is-server-side'
import consoleLogger from '../../../../modules/console-logger'
import options from '../../../../config/options'
import { getStoredItem } from '../../../../modules/stored-item'

const TPInitialize = () => {
  if (!isServerSide()) {
    const [consoleLog] = consoleLogger('InitPiano')
    window.tp = window['tp'] || []
    window.tp.push(['setAid', process.env.RAZZLE_SPLUS_PIANO_APP_ID])
    window.tp.push(['setLocale', options.Plus.vendor.locale])
    window.tp.push(['setCxenseSiteId', options.Plus.vendor.CxenseSiteId])
    consoleLog('AID', process.env.RAZZLE_SPLUS_PIANO_APP_ID)
    if (getStoredItem('dlabPianoDebug', true) === 'true') {
      window.tp.push(['setDebug', true])
    }
    //window.tp.push(['setSandbox', true])
    //window.tp.push(['setEndpoint', options.Plus.vendor.live.Endpoint])
    window.tp.push(['setEndpoint', options.Plus.vendor.live.Endpoint])
    window.tp.push(['setUsePianoIdUserProvider', true])
    window.tp.push(['setCustomVariable', 'isStolApp', isStolApp()])
    window.tp.push(['setUseTinypassAccounts', false])
    window.tp.push(['setCloudflareWorkerUrl', options.Plus.vendor.serverSideCookieAPI])
    window.tp.push([
      'addHandler',
      'experienceExecute',
      (e) => {
        consoleLog('PianoHandling', 'addHandler')
        // Tell Scheduler Piano is Ready
        window.callbackFunction('pianoLoaded')
        // add Window Function to retrieve loggedIn User
        window.getStolUser = () => {
          let user = getStoredItem('user', true)
          if (!user) {
            return null
          } else {
            return JSON.parse(user).email
          }
        }
      },
    ])
    setCustomVariablesPiano()
    onCheckoutStateChange()
    onLoginSuccess()
    onCheckoutComplete()
  }
  return (
    <Helmet>
      <script type="text/javascript" async="" src={options.Plus.vendor.scriptFile}></script>
    </Helmet>
  )
}

export default TPInitialize
