class ErrorStatus extends Error {
  constructor(status, ...params) {
    super(...params)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorStatus)
    }

    this.status = status
  }
}

export default ErrorStatus
