import React from 'react'

function SvgTwitter(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M21.848,5.894c-0.547,0.811 -1.233,1.519 -2.026,2.091l0,0.523c-0.002,1.1 -0.163,2.194 -0.476,3.249c-0.329,1.101 -0.817,2.148 -1.447,3.108c-0.637,0.985 -1.414,1.871 -2.306,2.633c-0.956,0.793 -2.047,1.41 -3.22,1.82c-1.297,0.462 -2.666,0.692 -4.042,0.682c-2.199,0.017 -4.355,-0.612 -6.199,-1.811c0.31,0.034 0.622,0.049 0.934,0.046c1.818,0.021 3.589,-0.585 5.013,-1.717c-0.829,-0.014 -1.633,-0.288 -2.297,-0.784c-0.674,-0.497 -1.172,-1.198 -1.419,-1.998c0.25,0.043 0.503,0.065 0.756,0.065c0.36,0.001 0.718,-0.046 1.065,-0.14c-0.912,-0.175 -1.733,-0.668 -2.315,-1.391c-0.611,-0.714 -0.943,-1.627 -0.934,-2.567c0.572,0.317 1.213,0.49 1.867,0.504c-0.571,-0.38 -1.034,-0.901 -1.344,-1.512c-0.325,-0.589 -0.492,-1.251 -0.486,-1.923c-0.002,-0.716 0.188,-1.419 0.551,-2.035c1.005,1.238 2.258,2.253 3.678,2.978c1.447,0.749 3.04,1.176 4.668,1.251c-0.066,-0.307 -0.101,-0.62 -0.103,-0.934c-0.021,-1.072 0.401,-2.106 1.167,-2.856c0.76,-0.753 1.788,-1.176 2.857,-1.176c1.115,0 2.183,0.459 2.949,1.269c0.902,-0.156 1.768,-0.472 2.558,-0.934c-0.289,0.938 -0.923,1.733 -1.774,2.222c0.804,-0.101 1.588,-0.325 2.325,-0.663Z" />
    </svg>
  )
}

export default SvgTwitter
