import errorLog from '../../../../modules/error-log'

const generatePathByRouteName = (routeOptions, { params = {}, type = 'path' }) => {
  if (!routeOptions) {
    errorLog({
      info: 'RoutesConfig - module - generatePathByRouteName',
      error: 'no routeOptions set',
    })
    return false
  }
  let namedRoutePath = routeOptions['path']
  const queryType = routeOptions[type]
  if (type === 'api' && queryType) {
    namedRoutePath = queryType
  }

  let ret = namedRoutePath.replace(/(:[^\\?]+\??)/g, (fullPattern, match) => {
    const paramName = match.replace(/:|\?/g, '')
    return params[paramName] || ''
  })

  if (ret !== '/' && ret.charAt(ret.length - 1) === '/') {
    ret = ret.slice(0, -1)
  }

  return ret
}

export default generatePathByRouteName
