import React from 'react'

function SvgSearch(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M15.8 14.3l5 5-1.5 1.5-5-5V15l-.3-.3a6.2 6.2 0 01-4.2 1.5 6.3 6.3 0 01-4.6-1.8 6.2 6.2 0 01-2-4.6 6.3 6.3 0 012-4.6 6.3 6.3 0 014.6-2 6.2 6.2 0 014.6 2 6.3 6.3 0 011.9 4.6 6.2 6.2 0 01-1.6 4.2l.3.3zM6.6 13a4.3 4.3 0 003.2 1.3 4.5 4.5 0 004.5-4.5A4.3 4.3 0 0013 6.6a4.3 4.3 0 00-3.2-1.3 4.3 4.3 0 00-3.2 1.3 4.3 4.3 0 00-1.3 3.2A4.3 4.3 0 006.6 13z" />
    </svg>
  )
}

export default SvgSearch
