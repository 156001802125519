import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  @media print {
    display: none;
  }
`

const AdUnit = ({ className, adId, mode, ...props }) => {
  let adDivId = `dlab_ad_${adId}`
  if (mode) {
    adDivId += `_${mode}`
  }

  return (
    <Wrapper {...{ className }} {...props}>
      <div id={adDivId} />
    </Wrapper>
  )
}

AdUnit.defaultProps = {
  callBackAdUnitIsEmpty: () => {},
}

AdUnit.propTypes = {
  adId: PropTypes.string,
}

export default AdUnit
