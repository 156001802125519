import React from 'react'

function SvgCamera(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M9 3h6l1.8 2H20a1.8 1.8 0 011.4.6A2 2 0 0122 7v12a2 2 0 01-.6 1.4 1.8 1.8 0 01-1.4.6H4a1.8 1.8 0 01-1.4-.6A2 2 0 012 19V7a2 2 0 01.6-1.4A1.8 1.8 0 014 5h3.2zm-.5 13.5A4.8 4.8 0 0012 18a4.8 4.8 0 003.5-1.5 5 5 0 000-7A4.8 4.8 0 0012 8a4.8 4.8 0 00-3.5 1.4 5 5 0 000 7.1zm1.3-1.3a3 3 0 01-1-2.2A3.2 3.2 0 0112 9.8a3.2 3.2 0 013.2 3.2 3 3 0 01-1 2.3 3.2 3.2 0 01-4.4 0z" />
    </svg>
  )
}

export default SvgCamera
