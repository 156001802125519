// check for a specific value in the current UserAgent. This is used to identify the App or iPhoneXs which are using the App.

import isServerSide from '../is-server-side'

const checkForValueInUserAgent = (value) => {
  const regexCode = new RegExp(value, 'i')
  return !isServerSide() && window.navigator && window.navigator.userAgent && regexCode.test(window.navigator.userAgent)
}

export default checkForValueInUserAgent
