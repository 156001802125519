import translate from '../../../../modules/translate'
import ContainerAd from '../../../../components/ContainerAd'
import React from 'react'
import styled from 'styled-components'
import Title from '../../../../components/Title'
import Text from '../../../../components/Text'
import List from '../../../../components/List'
import ContainerArticle from '../../../../components/ContainerArticle'

const StyledTitle = styled(Title)`
  font-size: 92px;
  color: ${(props) => props.theme.color.stol};
  font-weight: ${(props) => props.theme.fontWeight.regular};
  margin-bottom: 0;
`

const StyledSubtitle = styled(Title)`
  font-weight: ${(props) => props.theme.fontWeight.regular};
  color: ${(props) => props.theme.color.textPrimary};
  max-width: 470px;
`

const StyledText = styled(Text)`
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
`

const ErrorCode = styled(StyledText)`
  color: ${(props) => props.theme.color.textSecondary};
  margin-bottom: 1.5em;
`

const SmallSubTitle = styled(StyledText)`
  font-weight: ${(props) => props.theme.fontWeight.bold};
`

const StyledList = styled(List)`
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
  margin-bottom: 1.5em;
`

const StyledContainerArticle = styled(ContainerArticle)`
  margin-bottom: 1em;
`

const NotFoundMessage = ({ status, ...props }) => (
  <StyledContainerArticle {...props}>
    <StyledTitle>{translate('error_not_found_header')}</StyledTitle>
    <StyledSubtitle tag="h3">{translate('error_not_found_header_message')}</StyledSubtitle>
    <ErrorCode>{translate('error_not_found_code') + ' ' + status}</ErrorCode>
    <SmallSubTitle>{translate('error_not_found_possibilities')}</SmallSubTitle>
    <StyledList
      items={[
        translate('error_not_found_view_homepage', { link: '/' }),
        translate('error_not_found_to_search', { link: '/suche/bozen' }),
      ]}
    />
    <StyledText>{translate('error_not_found_contact', { mail: 'mailto:redaktion@stol.it' })}</StyledText>
    <ContainerAd adIds={['S3', 'S4']} noGapEdge />
  </StyledContainerArticle>
)

export default NotFoundMessage
