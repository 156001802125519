const options = require('../src/config/options')
const isServerSide = require('./modules/is-server-side')
const envApiPrefix = require('./modules/env-api-prefix')

const config = {
  restUrl: (path, version) => {
    version = version ? version : 'v1'
    let host = options.Api.host

    if (envApiPrefix() && isServerSide()) {
      host = envApiPrefix()
    }

    //remove double slashes
    path = path.replace(/^\//, '')
    return host + version + '/' + path
  },
  baseUrl: (path) => {
    const host = '/'

    //remove double slashes
    path = path.replace(/^\//, '')
    return host + path
  },
  authUrl: (path) => {
    const host = options.User.authUrl

    //remove double slashes
    path = path.replace(/^\//, '')
    return host + path
  },
  geoUrl: (path) => {
    let host = options.Geo.host

    //remove double slashes
    path = path.replace(/^\//, '')
    return host + path
  },
}

module.exports = config
