import React from 'react'

function MapMark(props) {
  return (
    <svg width="15.94" height="20.494" viewBox="0 0 15.94 20.494" {...props}>
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_44" width="15.94" height="20.494" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_19" clip-path="url(#clip-path)">
        <path
          id="Path_22"
          d="M7.97,0A7.97,7.97,0,0,0,0,7.971c0,5.977,7.97,12.524,7.97,12.524s7.97-6.547,7.97-12.524A7.97,7.97,0,0,0,7.97,0m0,11.385a3.416,3.416,0,1,1,3.416-3.416A3.415,3.415,0,0,1,7.97,11.386"
          transform="translate(0 -0.001)"
          fill="#b3b3b3"
        />
      </g>
    </svg>
  )
}

export default MapMark
