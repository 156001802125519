

import React from 'react'

function SvgLoggedIn(props) {
  return (
    <svg height="21px" width="21px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props} >
      <path fill="#d41d24" d="M6.7 15.1A14 14 0 0 1 12 14a14 14 0 0 1 5.3 1.1c1.8.8 2.7 1.7 2.7 2.9v2H4v-2c0-1.2.9-2.2 2.7-2.9zm8.1-4.3a4 4 0 0 1-5.6 0A3.8 3.8 0 0 1 8 8a4 4 0 0 1 1.2-2.8 3.9 3.9 0 0 1 5.6 0A4 4 0 0 1 16 8a3.8 3.8 0 0 1-1.2 2.8z"/>
    </svg>
  )
}


export default SvgLoggedIn
