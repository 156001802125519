import React from 'react'

function SvgArrowRight(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M8.3 16.6l4.6-4.6-4.6-4.6L9.7 6l6 6-6 6z" />
    </svg>
  )
}

export default SvgArrowRight
