import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import translate from '../../../../modules/translate'
import MessageError from '../../screens/MessageError'
import AppError from '../../screens/AppError'
import errorLog from '../../../../modules/error-log'
import NotFoundMessage from '../../components/NotFoundMessage'
import { Redirect } from 'react-router'
import ReactGA from 'react-ga'
import isServerSide from '../../../../modules/is-server-side'
import JobLoadError from '../../../JobWidgetPage/JobWidgetPageScreen/components/JobLoadError'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      catchedError: props.error || null,
      catchedInfo: props.info || (props.error && props.error.message) || null,
    }
  }

  componentDidCatch(error, info) {
    this.setState({ catchedError: error, catchedInfo: info })
  }

  render() {
    const { text, component, link, linkText, layoutDefault, children, view, onError, ...props } = this.props

    const errorOptions = {
      text: text,
      logError: this.state.catchedError,
      logInfo: this.state.catchedInfo,
    }
    if (!component && !text) {
      errorOptions.text = translate('error_default')
    }
    const errorStatus =
      (props.error && props.error.response && props.error.response.status) || (props.error && props.error.status)
    if (errorStatus === 404) {
      if (!isServerSide() && location) {
        ReactGA.set({ page: location.pathname })
        ReactGA.pageview(location.pathname, null, '404 - Seite nicht gefunden')
      }
      return <NotFoundMessage status={errorStatus} {...props} />
    }
    if (props.error && props.error.response) {
      errorLog({ error: props.error, info: props.logError, custom: false })
      if (onError) {
        onError(props.error.response)
      }
    }

    if (this.state.catchedError) {
      switch (view) {
        case 'app':
          return <AppError error={props && props.error && props.error.response} {...errorOptions} {...props} />
        case 'notfound':
          return <NotFoundMessage status={props.error && props.error.status} {...props} />
        case 'jobwidget':
          return (
            <JobLoadError
              component={component}
              link={link}
              linkText={linkText}
              layoutDefault={layoutDefault}
              {...errorOptions}
              {...props}
            />
          )
        case 'silent':
          return <></>
        default: {
          return (
            <MessageError
              component={component}
              link={link}
              linkText={linkText}
              layoutDefault={layoutDefault}
              {...errorOptions}
              {...props}
            />
          )
        }
      }
    }
    if (!children) return <Fragment />
    return children
  }
}

ErrorBoundary.defaultProps = {
  onError: () => {},
  text: '',
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  view: PropTypes.oneOf(['app', 'notfound']),
}

export default ErrorBoundary
