import React, { Component } from 'react'
import { Link as ReactLink } from 'react-router-dom'
import Text from '../Text'
import PropTypes from 'prop-types'
import Icon from '../Icon'
import styled from 'styled-components'
import WidgetContext from '../../bundles/components/WidgetContext'
import LinkChecker from '../LinkChecker'
import isServerSide from '../../modules/is-server-side'

const Wrapper = styled(Text)`
  color: ${(props) => props.theme.color.stol};
  text-decoration: none;
  cursor: default;
  outline: 0;
`

const WrapperLink = styled(Wrapper)`
  cursor: pointer;
  outline: 0;
  &:hover {
    text-decoration: ${(props) => (props.underlineOnHover ? 'underline' : 'none')};
  }
`

const ExternalIcon = styled((props) => <Icon icon="externalLink" {...props} />)`
  margin: auto 0.3em;
`

const createHandleClick = (to) => () => {
  const location = to()
  if (!isServerSide() && typeof location !== 'undefined') {
    document.location = location
  }
}

class Link extends Component {
  render() {
    const {
      children,
      to,
      title,
      underlineOnHover,
      itemRef,
      autoExternalLinkIcon,
      onClick,
      onMouseOver,
      onMouseLeave,
      forceTarget,
      ...props
    } = this.props

    if (onMouseOver || onMouseLeave) {
      return (
        <Wrapper
          as="span"
          ref={itemRef}
          {...props}
          onMouseOver={createHandleClick(onMouseOver)}
          onMouseLeave={createHandleClick(onMouseLeave)}
          title={title}
        >
          {children}
        </Wrapper>
      )
    }
    if (typeof to === 'undefined') {
      let NoToWrapper = Wrapper
      if (onClick) NoToWrapper = WrapperLink
      return (
        <NoToWrapper as="span" ref={itemRef} onClick={onClick} {...props}>
          {children}
        </NoToWrapper>
      )
    }

    const externalLink =
      typeof to !== 'object' && to.match && to.match(/^http/) && !to.match(/^http(s)?:\/\/(www\.)?stol.it/)
    let linkTarget = forceTarget ? forceTarget : externalLink ? '_blank' : '_self'
    const showIcon = (autoExternalLinkIcon === true && externalLink) || autoExternalLinkIcon === 'force'

    if (typeof to !== 'object' && to.match && to.match(/(^http|^mailto:|^tel:)/)) {
      return (
        <WrapperLink as="a" ref={itemRef} href={to} target={linkTarget} title={title} onClick={onClick} {...props}>
          {children}
          {showIcon && <ExternalIcon />}
        </WrapperLink>
      )
    }
    if (to && typeof to === 'object' && to.pathname && to.pathname.match(/(^http|^mailto:|^tel:)/)) {
      return (
        <WrapperLink
          as="a"
          ref={itemRef}
          href={to.pathname}
          target={linkTarget}
          onClick={onClick}
          title={title}
          {...props}
        >
          {children}
          {showIcon && <ExternalIcon />}
        </WrapperLink>
      )
    }
    if (to === '#') {
      return (
        <Wrapper as="span" ref={itemRef} title={title} {...props}>
          {children}
        </Wrapper>
      )
    }
    //onclick but no link destination passed
    if (onClick && !(to && (typeof to === 'string' || to.pathname))) {
      return (
        <WrapperLink as="span" ref={itemRef} title={title} {...props} onClick={createHandleClick(onClick)}>
          {children}
        </WrapperLink>
      )
    }
    return (
      <Wrapper
        as={LinkChecker}
        {...props}
        innerRef={itemRef}
        to={to}
        href={typeof to === 'object' ? to.pathname : to}
        onClick={onClick}
        title={title}
      >
        {children}
        {showIcon && <ExternalIcon />}
      </Wrapper>
    )
  }
}

Link.propTypes = {
  ...ReactLink.propTypes,
  underlineOnHover: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
  autoExternalLinkIcon: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['force'])]),
}
Link.defaultProps = {
  underlineOnHover: true,
  autoExternalLinkIcon: false,
}

Link.contextType = WidgetContext

export default Link
