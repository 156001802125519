//this modules check if an Object has specific keys.

import PropTypes from 'prop-types'

const hasItems = item => {
  if (!item) return false
  if (item && Array.isArray(item)) {
    if (item.length === 0) return false
  } else if (typeof item === 'object') {
    if (Object.keys(item).length === 0) return false
  }
  return true
}

hasItems.propTypes = {
  item: PropTypes.any,
}

export default hasItems
