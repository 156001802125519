import userSession from '../../../../topics/Profile/modules/user-session'

const logoutPiano = () => {
  window.tp = window.tp || []
  window.tp.push([
    'init',
    function () {
      window.tp.pianoId.logout(function () {
        userSession.clear()
      })
    },
  ])
}

export default logoutPiano
