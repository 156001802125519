const createQueryKey = (key, variables) => {
  const query = [key]
  if (variables) {
    const { location, ...queryOptions } = variables
    query[1] = queryOptions
    if (location) {
      query[2] = location
    }
  }
  return query
}

export default createQueryKey
