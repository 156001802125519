import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import truncate from '../../modules/truncate'

const styles = css`
  font-family: ${(props) => props.theme.font.primary};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSize.title1};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.title1};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.title1};
  }
  margin: 0.5em 0;
  color: ${(props) => props.theme.color.textPrimary};
  line-height: ${(props) => props.theme.lineHeight.title};
`

const Title = styled(({ tag, children, ...props }) => React.createElement(`${tag}`, props, children))`
  ${styles};
`

Title.propTypes = {
  ...truncate.propTypes,
  tag: PropTypes.string,
  children: PropTypes.node,
}

Title.defaultProps = {
  tag: 'h1',
}

export default truncate(Title)
