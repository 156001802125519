// TITLE defaultTheme

const defaultThemeDesktop = {
  mode: 'desktop',
  breakPoint: 1080,
  color: {
    textPrimary: 'rgba(51, 51, 51, 1)',
    textSecondary: 'rgba(0, 0, 0, .5)',
    textTertiary: 'rgba(0, 0, 0, .2)',
    textInverted: '#FFF',
    textInvertedSecondary: 'rgba(255, 255, 255, .6)',
    textInvertedTertiary: 'rgba(255, 255, 255, .2)',
    stol: '#D41D24',
    stolDark: '#B71C1C', // OnTour Color
    stolLight: '#D6373D', // for dark backgrounds
    pr: '#FFFAD4',
    background: '#EEE',
    backgroundSecondary: 'rgba(238, 238, 238, 0.3)',
    backgroundBorder: '#E5E5E5',
    backgroundContent: '#FFF',
    warning: 'rgba(133, 100, 3, 1)',
    success: 'green',
    multimedia: {
      backgroundPrimary: '#3B3E40',
      backgroundSecondary: '#4B5055',
      backgroundTertiary: '#5B6164',
    },
    skeletonScreen: 'rgba(209, 209, 209, .6)',
  },
  font: {
    primary: '"Splus Icon", "Fira Sans", sans-serif',
    secondary: 'Oswald, sans-serif',
  },
  fontSize: {
    smallX: '15px',
    small: '15px',
    base: '16px',
    text: '18px',
    title3: '20px',
    title2: '24px',
    title1: '28px',
    titleBigger: '38px',
  },
  fontWeight: {
    regular: 400,
    bold: 700,
  },
  lineHeight: {
    title: 1.2,
    topLine: 1.16,
    text: 1.4,
  },
  sizes: {
    gapCol: '40px',
    gapColHalf: '20px',
    gapEdge: '40px',
    gapVertical: '30px',
    gapVerticalHalf: '15px',
    col2: 'calc((100% - 200px) / 6)',
    col3: 'calc((100% - 200px) / 6 * 1.5 + 20px)',
    col4: 'calc((100% - 200px) / 6 * 2 + 40px)',
    col6: 'calc((100% - 200px) / 6 * 3 + 80px)',
    col8: 'calc((100% - 200px) / 6 * 4 + 120px)',
    col9: 'calc((100% - 200px) / 6 * 4.5 + 140px)',
    col10: 'calc((100% - 200px) / 6 * 5 + 160px)',
    col12: '100%',
  },
  shadow: {
    boxShadow: '3px 3px 3px 0 rgba(0, 0, 0, 0.16)',
  },
}

const defaultThemeTablet = {
  ...defaultThemeDesktop,
  mode: 'tablet',
  breakPoint: 1080,
  fontSize: {
    ...defaultThemeDesktop.fontSize,
    text: '17px',
  },
  sizes: {
    ...defaultThemeDesktop.sizes,
    gapEdge: '40px',
  },
  shadow: {
    ...defaultThemeDesktop.shadow,
  },
}

const defaultThemeMobile = {
  ...defaultThemeTablet,
  mode: 'mobile',
  breakPoint: 599,
  fontSize: {
    ...defaultThemeTablet.fontSize,
    smallX: '11px',
    small: '13px', //adjusted switch with media queries - exception: MapWeather
    base: '15px', //adjusted switch with media queries
    text: '16px', //adjusted switch with media queries - exception: HeaderDetailArticle, DetailArticle, VideoDetailArticle
    title3: '15px', //adjusted switch with media queries - exception: DescriptionOnTourDetailArticle, SkeletonDescriptionOnTourDetailArticle
    title2: '20px', //adjusted switch with media queries
    title1: '21px', //adjusted switch with media queries - exception: widget_video, QuoteDetailArticle, ListBoxDetailArticle
    titleBigger: '22px', //adjusted switch with media queries
  },
  sizes: {
    ...defaultThemeTablet.sizes,
    gapCol: '20px', //adjusted switch with media queries - exceptions: SmallLandscapeItemArticle, MapForeCastMountain(ResponsiveWrapper), SymbolsForeCastMountain
    gapColHalf: '10px', //adjusted switch with media queries - exceptions: BigVideoItemArticle, Header, ForecastAvalanche(BelowMap)
    gapEdge: '20px', //adjusted switch with media queries - exceptions: OnTourDetailArticle, BigItemArticle, PageHome, PageHomeSkeleton, DescriptionOnTourDetail(DescriptionWrapper), OnTourOverview(SkeletonBlockThreeArticle), HeadlinePaper, widget_video, StyledElementBelowTitle
    gapVertical: '20px', //adjusted switch with media queries - exceptions: BigItemArticle, PageHome, PageHomeSkeleton, BlockEventsAndAds(Wrapper), NearMe (StyledTitleTopic), DescriptionOnTourdetail (DetailsWrapper), OnTourOverview, Districtnav, widget_video, WidgetWeather(Skeleton too)
    gapVerticalHalf: '10px', //adjusted switch with media queries - exceptions: included in gapVertical exceptions
    col2: 'calc((100% - 100px) / 6)', //adjusted switch with media queries
    col3: 'calc((100% - 100px) / 6 * 1.5 + 10px)', //adjusted switch with media queries - exceptions: DescriptionOntourDetail(DetailsWrapper - Skeleton too)
    col4: 'calc((100% - 100px) / 6 * 2 + 20px)', //adjusted switch with media queries - exceptions: WidgetObituary
    col6: 'calc((100% - 100px) / 6 * 3 + 40px)', //adjusted switch with media queries - exceptions: SmallLandscapeItemArticle, widget_search_obituary, widget_videos (StyledItemArticle), WidgetWeather (Skeleton too)
    col8: 'calc((100% - 100px) / 6 * 4 + 60px)', //adjusted switch with media queries - exceptions: BigVideoItemArticle
    col9: 'calc((100% - 100px) / 6 * 4.5 + 70px)', //adjusted switch with media queries
    col10: 'calc((100% - 100px) / 6 * 5 + 80px)', //adjusted switch with media queries
  },
  shadow: {
    ...defaultThemeDesktop.shadow,
  },
}

const defaultThemeMobileS = {
  ...defaultThemeMobile,
  mode: 'mobileS',
  breakPoint: 450,
}

export default {
  default: defaultThemeDesktop,
  tablet: defaultThemeTablet,
  mobile: defaultThemeMobile,
  mobileS: defaultThemeMobileS,
}
