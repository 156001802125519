import React from 'react'
import { ThemeProvider } from 'styled-components'
import PropTypes from 'prop-types'
import { useScreenSize } from '../../modules/media-query'

const ThemeProviderResponsive = ({ theme, children, breakpoints, ...props }) => {
  const screenSize = useScreenSize()

  let themeToUse
  switch (screenSize) {
    case 'tablet':
      themeToUse = { ...theme.tablet, ...theme }
      break
    case 'mobile':
      themeToUse = { ...theme.mobile, ...theme }
      break
    case 'mobileS':
      themeToUse = { ...theme.mobileS, ...theme }
      break
    case 'desktop':
    default:
      themeToUse = { ...theme.default, ...theme }
  }
  return (
    <div>
      <ThemeProvider theme={themeToUse} {...props}>
        {children}
      </ThemeProvider>
    </div>
  )
}

ThemeProviderResponsive.propTypes = {
  theme: PropTypes.object.isRequired,
  children: PropTypes.node,
  breakpoints: PropTypes.shape({
    mobile: PropTypes.shape({
      maxWidth: PropTypes.number,
    }),
    tablet: PropTypes.shape({
      maxWidth: PropTypes.number,
    }),
  }),
}

export default ThemeProviderResponsive
