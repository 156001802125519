import isServerSide from '../is-server-side'
import { useEffect, useState } from 'react'

const objectTrueToString = (obj) => {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop) && obj[prop] && obj[prop].matches) {
      return prop
    }
  }
  return 'desktop'
}

const useScreenSize = () => {
  const [match, setMatch] = useState('desktop')

  const listener = () => {
    if (!isServerSide()) {
      const tablet = window.matchMedia('(max-width: 1080px) and (min-width: 600px)')
      const mobile = window.matchMedia('(max-width: 599px) and (min-width: 451px)')
      const mobileS = window.matchMedia('(max-width: 450px)')

      const key = objectTrueToString({ tablet, mobile, mobileS })
      const checkNoDuplicates = objectTrueToString({ mobileS, mobile, tablet })

      if (key !== match && key === checkNoDuplicates) {
        window.removeEventListener('resize', listener)
        setMatch(key)
      }
    }
  }
  if (!isServerSide()) {
    window.addEventListener('resize', listener)
    listener()
  }

  return match
}

const useMediaQuery = (query) => {
  const [match, setMatch] = useState(false)

  useEffect(() => {
    const media = window.matchMedia(query)
    const listener = () => {
      if (media.matches !== match) {
        setMatch(media.matches)
      }
    }
    listener()
    window.addEventListener('resize', listener)

    return () => window.removeEventListener('resize', listener)
  }, [match, query])

  return match
}

const useMobileKeyboard = () => {
  const [keyboard, setKeyboard] = useState(false)
  const mobile = useMediaQuery('(max-width:767px)')

  useEffect(() => {
    const handleResize = () => {
      setKeyboard(mobile && window.screen.height - window.visualViewport.height > 300)
    }
    if (window.visualViewport) {
      handleResize()
      window.visualViewport.addEventListener('resize', handleResize)
    }
    return () => {
      window.visualViewport && window.visualViewport.removeEventListener('resize', handleResize)
    }
  }, [mobile])
  return keyboard
}

export { useMediaQuery, useScreenSize, useMobileKeyboard }
