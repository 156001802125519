import React from 'react'

const JobIcon = ({ source, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67 67" fill="#bbb9c6" {...props}>
    <g id="suitcase-job-work-checkmark" transform="translate(0 -0.014)">
      <path
        id="Path_770"
        data-name="Path 770"
        d="M 30.548000000000002 58.641 L 16.662 58.641 C 12.061 58.642 8.33 54.911 8.33 50.31 L 8.33 26.71 C 8.329 22.875 11.438 19.765 15.273 19.766 L 51.373 19.766 C 55.207 19.765 58.316 22.874 58.316 26.708 L 58.316 30.873 M 33 36.014 L 9 36.014 M 43.043 19.433 L 43.043 13.88 C 43.043 10.813 40.557 8.327 37.49 8.326 L 29.16 8.326 C 26.093 8.326 23.606 10.813 23.606 13.88 L 23.606 19.434"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="4.5"
      />
      <circle
        id="Ellipse_6"
        data-name="Ellipse 6"
        cx="12"
        cy="12"
        r="12"
        transform="translate(38 38.014)"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="4.5"
      />
      <path
        id="Path_772"
        data-name="Path 772"
        d="M16.75,18.775l2.777,2.777,4.165-4.165"
        transform="translate(29.767 31.144)"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="4.5"
      />
      <rect
        id="Rectangle_1581"
        data-name="Rectangle 1581"
        width="67"
        height="67"
        transform="translate(0 0.014)"
        fill="none"
      />
    </g>
  </svg>
)

export default JobIcon
