import { getStoredItem } from '../stored-item'

const consoleLogger = (name) => {
  const consoleLog = (...props) => {
    let shouldLog = getStoredItem(`dlab${name}Test`, true)

    let description = name
    let item = props[0]

    if (props[2]) {
      if (shouldLog !== 'true') {
        shouldLog = getStoredItem(`dlab${props[2]}Test`, true)
      }
      description += ` | ${props[2]}`
    }

    if (props.length >= 2) {
      const [logDescription, logItem] = props
      description = `${description} - ${logDescription}`
      item = logItem
    }

    if (!name) {
      return false
    }

    if (shouldLog === 'true') {
      console.log(
        `%c ${description}`,
        'color: white; border-radius: 3px; padding:2px; font-size: 10px; background: #0560c6;',
        item,
      )
    }
  }

  return [consoleLog]
}

export default consoleLogger
