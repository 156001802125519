import React from 'react'

function SvgInstagram(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M22.29 12c0 2 0 3.46-.07 4.25a6.29 6.29 0 01-1.66 4.31 6.29 6.29 0 01-4.31 1.66c-.79 0-2.21.07-4.25.07s-3.46 0-4.25-.07a6.29 6.29 0 01-4.31-1.66 6.29 6.29 0 01-1.66-4.31c0-.79-.07-2.21-.07-4.25s0-3.46.07-4.25a6.29 6.29 0 011.66-4.31 6.29 6.29 0 014.31-1.66c.79 0 2.21-.07 4.25-.07s3.46 0 4.25.07a6.29 6.29 0 014.31 1.66 6.29 6.29 0 011.66 4.31c.04.79.07 2.25.07 4.25zM13 3.56H8.29a9.83 9.83 0 00-1.38.13 5.51 5.51 0 00-1 .25 3.7 3.7 0 00-1.18.77A3.7 3.7 0 004 5.93a5.51 5.51 0 00-.25 1 9.83 9.83 0 00-.15 1.34v7.42a9.83 9.83 0 00.13 1.38 5.51 5.51 0 00.25 1 3.7 3.7 0 00.77 1.18 3.7 3.7 0 001.18.75 5.51 5.51 0 001 .25 9.83 9.83 0 001.38.13h7.46a9.83 9.83 0 001.38-.13 5.51 5.51 0 001-.25A3.42 3.42 0 0020 18.07a5.51 5.51 0 00.25-1 9.83 9.83 0 00.13-1.38V14.4 11 9.58 8.29a9.83 9.83 0 00-.13-1.38 5.51 5.51 0 00-.25-1 3.7 3.7 0 00-.77-1.18A3.7 3.7 0 0018.07 4a5.51 5.51 0 00-1-.25 9.83 9.83 0 00-1.38-.13H14.4c-.29 0-.75-.07-1.4-.06zm2.72 4.7A5.13 5.13 0 0117.28 12 5.26 5.26 0 0112 17.28 5.26 5.26 0 016.72 12 5.26 5.26 0 0112 6.72a5.13 5.13 0 013.74 1.54zm-1.32 6.16a3.4 3.4 0 000-4.84 3.4 3.4 0 00-4.84 0 3.4 3.4 0 000 4.84 3.4 3.4 0 004.84 0zm3.94-8.78a1.23 1.23 0 11-.87-.36 1.19 1.19 0 01.89.36z" />
    </svg>
  )
}

export default SvgInstagram
