import React from 'react'

function SvgArrowUp(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M7.4 15.7L6 14.3l6-6 6 6-1.4 1.4-4.6-4.6z" />
    </svg>
  )
}

export default SvgArrowUp
