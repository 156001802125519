import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Text = styled(({ tag, inheritstyle, ...props }) => React.createElement(`${tag}`, props))`
  color: ${(props) => (props.inherit === 1 ? 'inherit' : props.theme.color.textPrimary)};
  font-family: ${(props) => (props.inherit === 1 ? 'inherit' : props.theme.font.primary)};
  font-size: ${(props) => (props.inherit === 1 ? 'inherit' : props.theme.fontSize.base)};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => (props.inherit === 1 ? 'inherit' : props.theme.tablet.fontSize.base)};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => (props.inherit === 1 ? 'inherit' : props.theme.mobile.fontSize.base)};
  }
  line-height: ${(props) => (props.inherit === 1 ? 'inherit' : props.theme.lineHeight.text)};
  margin: 0;
`

Text.propTypes = {
  children: PropTypes.node,
  tag: PropTypes.oneOf(['h2', 'p', 'div']),
}

Text.defaultProps = {
  inherit: 0,
  tag: 'p',
}

export default Text
