import React from 'react'

function SvgMenu(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M3 6h18v2H3zm0 7v-2h18v2zm0 5v-2h18v2z" />
    </svg>
  )
}

export default SvgMenu
