import { css } from 'styled-components'
import bottomLine from './bottom-line'

const bottomLineAfter = css`
  position: relative;

  &:after {
    content: ' ';
    position: absolute;
    left: ${props => props.theme.sizes.gapEdge};
    right: ${props => props.theme.sizes.gapEdge};
    bottom: 0;
    ${bottomLine};
  }
  @media(max-width: ${props => props.theme.tablet.breakPoint + 'px'}){
    &:after {
      left: ${props => props.theme.tablet.sizes.gapEdge};
      right: ${props => props.theme.tablet.sizes.gapEdge};
    }
  }
  @media(max-width: ${props => props.theme.mobile.breakPoint + 'px'}){
    &:after {
      left: ${props => props.theme.mobile.sizes.gapEdge};
      right: ${props => props.theme.mobile.sizes.gapEdge};
    }
  }
`

export default bottomLineAfter
