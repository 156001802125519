import React from 'react'

function Article(props) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0" y="0" width="24" height="24" fillOpacity="0" fillRule="nonzero" />
      <path
        d="M21.331,0l-18.665,0c-1.461,0.004 -2.662,1.205 -2.666,2.666l0,18.67c0.004,1.461 1.205,2.662 2.666,2.666l18.67,0c1.461,-0.004 2.662,-1.205 2.666,-2.666l0,-18.67c-0.004,-1.462 -1.208,-2.664 -2.671,-2.666Zm-6.666,18.669l-9.335,0l-0,-2.671l9.335,0l-0,2.671Zm4.004,-5.337l-13.336,0l-0,-2.666l13.336,-0l0,2.666Zm0,-5.333l-13.336,0l-0,-2.666l13.336,-0l0,2.666Z"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default Article
