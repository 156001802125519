import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  padding-left: ${(props) => props.theme.sizes.gapEdge};
  padding-right: ${(props) => props.theme.sizes.gapEdge};
  position: relative;
  box-sizing: border-box;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.tablet.sizes.gapEdge};
    padding-right: ${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.mobile.sizes.gapEdge};
    padding-right: ${(props) => props.theme.mobile.sizes.gapEdge};
  }
`

const Inner = styled.div`
  width: ${(props) => props.theme.sizes.col8};
  margin: auto;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: ${(props) => props.theme.tablet.sizes.col8};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: ${(props) => props.theme.mobile.sizes.col8};
  }
  @media (max-width: 1080px) {
    width: 100%;
  }
`

const ContainerArticle = ({ children, ...props }) => (
  <Wrapper {...props}>
    <Inner>{children}</Inner>
  </Wrapper>
)

ContainerArticle.propTypes = {
  children: PropTypes.node,
}

export default ContainerArticle
