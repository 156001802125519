import React from 'react'

function SvgExternalLink(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M18.56 14.05v4.1a3.7 3.7 0 01-3.69 3.68H4.21a3.55 3.55 0 01-2.6-1.08 3.55 3.55 0 01-1.08-2.6V7.49a3.55 3.55 0 011.08-2.6 3.55 3.55 0 012.6-1.08h9a.36.36 0 01.29.11.41.41 0 01.12.3V5a.4.4 0 01-.12.29.36.36 0 01-.29.11h-9a2 2 0 00-1.44.61 2 2 0 00-.6 1.44v10.7a2 2 0 00.6 1.44 2 2 0 001.44.6h10.66a2 2 0 002.05-2v-4.1a.4.4 0 01.11-.3.44.44 0 01.3-.11h.82a.44.44 0 01.29.11.41.41 0 01.12.26zM23.47 3v6.54a.82.82 0 01-.82.82.78.78 0 01-.57-.24l-2.26-2.26-8.34 8.35a.41.41 0 01-.3.13.37.37 0 01-.29-.13l-1.46-1.46a.37.37 0 01-.13-.29.41.41 0 01.13-.3l8.34-8.34-2.25-2.26a.81.81 0 010-1.15.78.78 0 01.58-.24h6.55a.8.8 0 01.58.24.78.78 0 01.24.59z" />
    </svg>
  )
}

export default SvgExternalLink
