import { getStoredItem, clearStoredItem } from '../../../../modules/stored-item'
import isServerSide from '../../../../modules/is-server-side'

const setCustomVariablesPiano = () => {
  if (!isServerSide()) {
    window.tp = window.tp || []
    if (getStoredItem('dlabSPlusAuth', true) === 'true') {
      clearStoredItem('spk', true)
      window.tp.push(['setCustomVariable', 'enableSPlus', 0])
    }

    if (window.athesia_react) {
      if (window.athesia_react.app_os) {
        window.tp.push(['setCustomVariable', 'app_os', window.athesia_react.app_os])
      }
      if (window.athesia_react.app_ver) {
        window.tp.push(['setCustomVariable', 'app_ver', window.athesia_react.app_ver])
      }
      if (window.athesia_react.app_env) {
        window.tp.push(['setCustomVariable', 'app_env', window.athesia_react.app_env])
      }
    }
  }
}

export default setCustomVariablesPiano
