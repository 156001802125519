// replaces

import flatten from 'array-flatten'

const reactReplace = (array, args) => {
  if (typeof args === 'undefined') {
    return array
  }
  if (typeof array === 'string') {
    array = [array]
  }
  const match = /({[\w]+})/g
  array.forEach((value, i) => {
    if (typeof value !== 'string') return
    array[i] = value.split(match)
  })
  array = flatten(array)
  Object.keys(args).forEach(key => {
    array.forEach((string, index) => {
      if (typeof string !== 'string') return
      if (key === string.replace(/(^{|}$)/g, '')) {
        array[index] = args[key]
      }
    })
  })
  return simplyArray(array)
}

function simplyArray(array) {
  const onlyString = array.reduce((a, entry) => a && typeof entry === 'string', true)
  return onlyString ? array.join('') : array
}

export default reactReplace
