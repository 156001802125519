import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { WidgetConsumer } from '../../bundles/components/WidgetContext'

const Wrapper = styled(Link)`
  cursor: pointer;

  &:hover {
    cursor: pointer;
    text-decoration: ${(props) => (props.underlineOnHover ? 'underline' : 'none')};
  }
`

const StyledA = styled.a``

StyledA.componentStyle = Wrapper.componentStyle

const LinkChecker = ({ ...props }) => (
  <WidgetConsumer>
    {(isWidget) =>
      isWidget && props.to ? (
        <StyledA {...props} href={typeof props.to === 'string' ? props.to : props.to.pathname} />
      ) : (
        <Wrapper {...props} />
      )
    }
  </WidgetConsumer>
)

export default LinkChecker
