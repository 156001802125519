import React from 'react'
import menuStatic from '../../config/layouts/menu.json'
import menuParseAndEnrich from '../../modules/menu-parse-and-enrich'
import { useQuery } from '@tanstack/react-query'
import routes from '../../topics/routes'

const ContextMenu = React.createContext({})

const MenuProvider = ({ children, isStorybook }) => {
  const menuResponse = useQuery(routes.getQueryKey('menu'))
  const newstickerResponse = useQuery(routes.getQueryKey('newsticker'))

  let menuData = menuStatic
  if (menuResponse && menuResponse.data && !isStorybook) {
    menuData = menuResponse.data
  }
  if (newstickerResponse && newstickerResponse.data && newstickerResponse.data.meta && !isStorybook) {
    if (newstickerResponse.data.meta.weather) {
      menuData.meta.weather = newstickerResponse.data.meta.weather
    }
    if (newstickerResponse.data.meta.logo) {
      menuData.meta.logo = newstickerResponse.data.meta.logo
    }
  }

  return <ContextMenu.Provider value={menuParseAndEnrich(menuData)}>{children}</ContextMenu.Provider>
}

export default ContextMenu

export { MenuProvider }
