import React from 'react'

function SvgCross(props) {
  return (
    <svg viewBox="0 0 16.369 21.969" {...props}>
      <path d="M10.213 21.969V10.4h6.156V6.341h-6.156V0H6.155v6.341H-.001V10.4h6.156v11.57" fill="currentColor" />
    </svg>
  )
}

export default SvgCross
