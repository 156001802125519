import consoleLogger from '../../../modules/console-logger'

const [consoleLog] = consoleLogger('Scheduler')

function internalTest() {
  consoleLog('internalTest', 'I am a internal test function')

  window.callbackFunction('__scheduler_onTestEvent')
}

function kickoffRefreshAds() {
  waitForWindowElement()
}

function waitForWindowElement() {
  if (typeof window.refreshAds !== 'undefined') {
    consoleLog('internalTest', 'Refreshing!')
    window.refreshAds()
  } else {
    setTimeout(waitForWindowElement, 100)
  }
}

export default {
  internalTest,
  kickoffRefreshAds,
}
