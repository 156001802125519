import options from '../../../../config/options'
import consoleLogger from '../../../../modules/console-logger'

const hasRequiredFields = (userData) => {
  const [consoleLog] = consoleLogger('HasRequiredCustomFields')
  if (userData === {}) {
    return false
  }

  const requiredFields = options.User.requiredUserData

  const hasRequiredCustomFields = requiredFields.every((requiredField) => {
    consoleLog('required field', requiredField, 'UserData')

    const isValid = !!userData[requiredField]
    consoleLog('has required field', userData[requiredField] + ' ' + isValid, 'UserData')
    return isValid
  })

  consoleLog('has ALL required fields', hasRequiredCustomFields, 'UserData')

  return hasRequiredCustomFields
}

export default hasRequiredFields
