import internalCodeLib from '../code-blocks/internalCodeLib'

const timetables = {
  ShowAds: {
    id: 'ShowAds',
    label: 'Show Ads',
    internalCode: {
      library: internalCodeLib,
      function: 'kickoffRefreshAds',
    },
    neededEventIds: ['pianoLoaded', 'gptLoaded'],
  },
}

export default timetables
