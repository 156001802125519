import { Component } from 'react'
import { withRouter } from 'react-router'
import isServerSide from '../../modules/is-server-side'

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname && this.props.location.pathname !== '/') {
      if (!isServerSide()) {
        window.scrollTo(0, 0)
        document.body.style.overflow = 'auto' //do not delete this line - otherwise the navigation will fail
      }
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)
