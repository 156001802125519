import fetchData from '../../../../modules/fetch-data'
import { setStoredItem, getStoredItem, clearStoredItem } from '../../../../modules/stored-item'
import setBackendUserState from '../../../../modules/set-backend-user-state'
import getToken from '../../../../vendor/Piano/modules/get-token'
import isServerSide from '../../../../modules/is-server-side'

const clear = () => {
  clearUser()
  clearStoredItem('login_redirect', true)
  clearStoredItem('access_token_v2', true)
  clearStoredItem('spk', true)
  if (!isServerSide()) {
    document.cookie = 'stol_login_be=; Max-Age=-99999999;'
    document.cookie = 'stol_session=; Max-Age=-99999999;'
  }
  setBackendUserState()
}

const clearUser = () => {
  clearStoredItem('user', true)
}

const add = ({ pianoIdToken, redirectAfterLogin, onError = () => {}, onSuccess = () => {} }) => {
  const options = {
    location: 'user',
    access: pianoIdToken,
    onError,
  }
  fetchData(options).then((user) => {
    if (user) {
      storeAccessToken(pianoIdToken)
      user.lastUpdate = new Date()
      setStoredItem('user', JSON.stringify(user), true)
      if (redirectAfterLogin) setStoredItem('login_redirect', redirectAfterLogin, true)
      onSuccess(user)
      setBackendUserState()
      return true
    }
  })
  return false
}

const storeAccessToken = (token) => {
  return setStoredItem('access_token_v2', token, true)
}

const accessTokenV2 = getStoredItem('access_token_v2', true)

const getAccessToken = () => {
  const accessToken = getStoredItem('access_token', true)
  const pianoToken = getToken()

  if (pianoToken) {
    return pianoToken
  }

  if (accessTokenV2) {
    return accessTokenV2
  }

  if (accessToken) {
    return accessToken
  }

  return false
}

const getData = () => {
  return JSON.parse(getStoredItem('user', true))
}

const storeData = (user) => {
  setStoredItem('user', JSON.stringify(user), true)
}

const isValid = () => {
  if (getAccessToken() && getData()) return true
  return undefined
}

const getAppData = () => {
  if (!isServerSide()) {
    return window.athesia_react && window.athesia_react.user
  }
  return false
}

const userSession = {
  clear,
  clearUser,
  add,
  getAccessToken,
  storeAccessToken,
  accessTokenV2,
  getData,
  storeData,
  getAppData,
  isValid,
}

export default userSession
