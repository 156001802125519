import React from 'react'

function SvgEye(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M5.3 6.6A11.6 11.6 0 0112 4.5 11.8 11.8 0 0123 12a11.6 11.6 0 01-4.3 5.4 11.6 11.6 0 01-6.7 2.1 11.6 11.6 0 01-6.7-2A11.6 11.6 0 011 12a11.6 11.6 0 014.3-5.4zm3.2 9a5 5 0 007 0 5 5 0 000-7.1 5 5 0 00-7 0 5 5 0 000 7zm1.4-5.7a3 3 0 014.2 0 3 3 0 010 4.2 3 3 0 01-4.2 0 3 3 0 010-4.2z" />
    </svg>
  )
}

export default SvgEye
