import React from 'react'
import AdBlockerPiano from './components/AdBlockerPiano'
import InitializePiano from './components/InitializePiano'

const Piano = ({ ...props }) => (
  <React.Fragment {...props}>
    <InitializePiano />
    <AdBlockerPiano />
  </React.Fragment>
)

export default Piano
