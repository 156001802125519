import React from 'react'

function SvgWeather(props) {
  return (
    <svg viewBox="0 0 32 24" {...props}>
      <path d="M13.4 13.2A5.8 5.8 0 0115.1 9a6 6 0 10-5.1 9.8 4.4 4.4 0 012.9-5.5 2.6 2.6 0 01.5-.2z" />
      <path d="M10.4 2.8L8.6 6H12zm-3.7 4l-3.5-1 1.1 3.5zm-3.3 4.5L0 12.9l3.4 1.7zM3.2 20l3.6-1-2.4-2.4zM17.6 5.6L14 6.9l1.8 1.7a5.2 5.2 0 011-.7zM28.3 14H28l.1-.8a2.4 2.4 0 00-2.3-2.4 2.3 2.3 0 00-1.9 1 4.9 4.9 0 00-9.6 1.5v.8a3.6 3.6 0 100 7.2h14.1a3.6 3.6 0 100-7.3z" />
    </svg>
  )
}

export default SvgWeather
