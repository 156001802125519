import PropTypes from 'prop-types'
import userSession from '../../../../topics/Profile/modules/user-session'
import errorLog from '../../../../modules/error-log'
import consoleLogger from '../../../../modules/console-logger'
import isServerSide from '../../../../modules/is-server-side'

const [consoleLog] = consoleLogger('PianoLoginByToken')

const loginByTokenPiano = (token) => {
  if (!isServerSide()) {
    const piano = window.tp
    const pianoId = piano && piano.pianoId
    if (pianoId) {
      try {
        pianoId.loginByToken(token)
      } catch (e) {
        consoleLog('ERROR - piano login with token', e)
        errorLog({ error: e, info: 'login piano - wrong token' })
      }
      if (pianoId.isUserValid()) {
        consoleLog('SUCCESS user valid', pianoId.isUserValid())
        return true
      }
      return false
    }
    return false
  }
  return false
}

const loginPiano = ({ token, createSession = true }) => {
  if (!token) return false

  if (!isServerSide()) {
    if (createSession) {
      userSession.add({ pianoIdToken: token })
    }
    consoleLog('token:', token)
    const loginSuccess = loginByTokenPiano(token)
    return loginSuccess
  }
  return false
}

loginPiano.propTypes = {
  token: PropTypes.string.isRequired,
}

export default loginPiano
