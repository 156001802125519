/*
  A module collecting functions concerning localStorage/sessionStorage items
  The functions can be replaced with other logic if needed
 */

import { useState } from 'react'
import isServerSide from '../is-server-side'

/**
 * Stores an item in the sessionStorage or localstorage
 * @param key
 * @param dataToStore
 * @param permanent pass true if the item is to be stored in localStorage instead of sessionStorage
 */
const setStoredItem = (key, dataToStore, permanent = false) => {
  //setStoredItem
  if (permanent === true) {
    if (!isServerSide() && window.localStorage) {
      window.localStorage.setItem(key, dataToStore)
    } else {
      return null
    }
  } else {
    if (!isServerSide() && window.sessionStorage) {
      window.sessionStorage.setItem(key, JSON.stringify(dataToStore))
    } else {
      return null
    }
  }
}

/**
 * Returns a stored item from the sessionStorage or localstorage
 * @param key
 * @param permanent pass true if the item is to be stored in localStorage instead of sessionStorage
 */
const getStoredItem = (key, permanent = false) => {
  if (permanent === true) {
    if (!isServerSide() && window.localStorage) {
      return window.localStorage.getItem(key)
    } else {
      return null
    }
  } else {
    if (!isServerSide() && window.sessionStorage) {
      return JSON.parse(window.sessionStorage.getItem(key))
    } else {
      return null
    }
  }
}

/**
 * Removes a stored item from the sessionStorage or localstorage
 * @param key
 * @param permanent pass true if the item is to be stored in localStorage instead of sessionStorage
 */
const clearStoredItem = (key, permanent = false) => {
  if (permanent === true) {
    if (!isServerSide() && window.localStorage) {
      if (key.length > 0) {
        window.localStorage.removeItem(key)
      } else {
        window.localStorage.clear()
      }
    }
  } else {
    if (!isServerSide() && window.sessionStorage) {
      if (key.length > 0) {
        window.sessionStorage.removeItem(key)
      } else {
        window.sessionStorage.clear()
      }
    }
  }
}

/**
 * An EventListener for stored items
 * @param key
 */
const useListenerStoredItem = ({ key }) => {
  const [storedItem, setStoredItem] = useState()

  const onStorageEvent = (storageEvent) => {
    if (storageEvent.key === key) {
      return setStoredItem({
        key: storageEvent.key,
        oldValue: storageEvent.oldValue,
        newValue: storageEvent.newValue,
      })
    }
    return false
  }

  if (!isServerSide()) {
    window.addEventListener('storage', onStorageEvent, false)
  }

  return storedItem
}

export { setStoredItem, getStoredItem, clearStoredItem, useListenerStoredItem }
