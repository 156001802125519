import React from 'react'

function SvgLinkedin(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M6.67 4.46A2.1 2.1 0 016 6.09a2.5 2.5 0 01-1.81.66 2.42 2.42 0 01-1.77-.66 2.2 2.2 0 01-.67-1.63 2.15 2.15 0 01.65-1.64 2.54 2.54 0 011.81-.65A2.47 2.47 0 016 2.82a2.26 2.26 0 01.67 1.64zm-.28 4.1v13.27H2V8.56zm15.9 5.66v7.61h-4.41v-7.1a3.87 3.87 0 00-.54-2.2 1.92 1.92 0 00-1.7-.8 2.21 2.21 0 00-1.41.46 2.86 2.86 0 00-.85 1.15 3.18 3.18 0 00-.15 1.08v7.41h-4.4v-8.66-4.61h4.4v1.93a5.19 5.19 0 01.54-.75 5.37 5.37 0 01.76-.7 3.56 3.56 0 011.17-.58 5.24 5.24 0 011.53-.21 4.76 4.76 0 013.68 1.52 6.37 6.37 0 011.38 4.45z" />
    </svg>
  )
}

export default SvgLinkedin
