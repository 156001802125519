import { useEffect, useState } from 'react'
import isServerSide from '../../../../modules/is-server-side'

const useReadyPiano = () => {
  const [isReady, setIsReady] = useState(false)

  if (!isServerSide() && window.tp && window.tp.aid && !isReady) {
    setIsReady(true)
  }

  useEffect(() => {
    window.tp = window.tp || []
    window.tp.push([
      'init',
      function () {
        setIsReady(true)
      },
    ])
  }, [])

  return isReady
}

export default useReadyPiano
