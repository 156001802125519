import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import joberror from '../img/joberror.svg'
import Text from '../../../../../components/Text'
import errorLog from '../../../../../modules/error-log'
import isDebug from '../../../../../modules/is-debug'

const Wrapper = styled.div`
  width: 100%;
`
const ErrorImg = styled.img`
  width: 100%;
`

const ContentPlacer = styled.div`
  margin: 2.5em auto;
  text-align: center;
  border-top: ${(props) => (props.isWidget ? '0' : '2px solid')};
  border-color: #00000033;
  padding-top: ${(props) => (props.isWidget ? '0' : '4em')};
`

const ImgPlacer = styled.div`
  width: 100px;
  margin-left: auto;
  margin-right: auto;
`
const StyledText = styled(Text)`
  margin-top: 10px;
  color: #d41e25;
  font-family: Fira Sans, Regular;
  font-size: 18px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
`

const StyledTitle = styled(Text)`
  color: #d41e25;
  font-size: 78px;

  font-family: Fira Sans, Regular;
`

const JobLoadError = ({
  text,
  link,
  linkText,
  component,
  logError,
  logInfo,
  inverted,
  error,
  noResult = false,
  isWidget,
  ...props
}) => {
  if (logError || logInfo) errorLog({ error: logError, info: logInfo })

  if ((process.env.NODE_ENV === 'development' || isDebug()) && !noResult) {
    return (
      <React.Fragment>
        <h1>{JSON.stringify(logError)}</h1>
        <p>{JSON.stringify(logInfo)}</p>
      </React.Fragment>
    )
  } else {
    return (
      <Wrapper {...props}>
        <ContentPlacer isWidget={isWidget}>
          <ImgPlacer>
            <ErrorImg src={joberror}></ErrorImg>
          </ImgPlacer>
          {noResult && (
            <StyledText>
              Es konnten keine passenden Job-Inserate zu Ihrer Suche gefunden werden. Bitte überprüfen Sie Ihre
              Suchkriterien.
            </StyledText>
          )}
          {!noResult && (
            <React.Fragment>
              <StyledTitle>Oops!</StyledTitle>
              <StyledText>Leider konnte die Seite nicht geladen werden.</StyledText>
            </React.Fragment>
          )}
        </ContentPlacer>
      </Wrapper>
    )
  }
}
JobLoadError.propTypes = {}
JobLoadError.defaultProps = {}
export default JobLoadError
