import fetch from 'cross-fetch'
import Config from '../../config'
import PropTypes from 'prop-types'
import hasItems from '../has-items'
import consoleLogger from '../console-logger'
import ErrorStatus from '../error-status'
import isServerSide from '../is-server-side'
import isStolApp from '../is-stolapp'

const FETCH_METHODS = {
  POST: 'POST',
  GET: 'GET',
  DELETE: 'DELETE',
}
const FETCH_VERSION = {
  V1: 'v1',
  V2: 'v2',
}
const actionPOST = ([actionArguments], props, signal) =>
  fetchData({ method: FETCH_METHODS.POST, ...props, ...actionArguments }, signal)

const actionGET = ([actionArguments], props, signal) =>
  fetchData({ method: FETCH_METHODS.GET, ...props, ...actionArguments }, signal)

const actionMETHOD = ([actionArguments], props, signal) => fetchData({ ...props, ...actionArguments }, signal)
const [consoleLog] = consoleLogger('FetchData')
const fetchData = (
  {
    location,
    path,
    baseUrl,
    authUrl,
    version,
    jobWidget,
    access,
    accessType = 'Bearer',
    userAgent,
    method,
    initialData,
    data,
    onError = () => {},
    onSuccess = () => {},
  },
  abortController,
) => {
  let fetchPath = path
  if (location) fetchPath = Config.restUrl(location, version)
  if (baseUrl) fetchPath = Config.baseUrl(baseUrl)
  if (authUrl) fetchPath = Config.authUrl(authUrl)
  const options = {
    headers: {
      Accept: 'application/json',
    },
  }
  if (abortController && hasItems(abortController.signal)) options['signal'] = abortController.signal
  if (access) {
    options['headers']['Authorization'] = `${accessType} ${access}`
  }
  if (!userAgent && isServerSide()) {
    userAgent = 'bot'
  }
  if (userAgent) {
    options['headers']['User-Agent'] = userAgent
  }

  if (jobWidget) {
    if (!isStolApp()) {
      //use SLC cookie
      options['credentials'] = 'include'
    }
    if (isStolApp() && !isServerSide()) {
      //pass ad id from app
      options['headers']['x-ad-id'] = window.device_native_advertising_identifier || null
    }
  }
  if (method) {
    options['method'] = method
  }
  if (data) {
    options['body'] = JSON.stringify(data)
    options['headers']['Content-Type'] = 'application/json'
  }
  consoleLog('fetch path', fetchPath)
  consoleLog('fetch options', options)
  return fetch(fetchPath, options).then((res) => {
    if (res.status >= 400 && res.status !== 401) {
      const error = { error: { http_status: res.status, message: 'Bad response from server' } }
      consoleLog('ERROR status >= 400', error)
      onError(error)
      throw new ErrorStatus(error.error.http_status, `${error.error.http_status} - ${error.error.message}`)
    }
    if (res.status === 201) {
      consoleLog('SUCCESS')

      return res.body ? res.json() : { status: 201 }
    }
    consoleLog('SUCCESS')

    onSuccess(res)
    return res.json()
  })
}

fetchData.propTypes = {
  location: PropTypes.string,
  path: PropTypes.string,
  baseUrl: PropTypes.string,
  version: PropTypes.oneOf(Object.values(FETCH_VERSION)),
  access: PropTypes.string,
  userAgent: PropTypes.string,
  method: PropTypes.oneOf(Object.values(FETCH_METHODS)),
  bodyData: PropTypes.object,
  onError: PropTypes.func,
}

fetchData.defaultProps = {
  onError: () => {},
}

export default fetchData

export { FETCH_METHODS, FETCH_VERSION, actionPOST, actionGET, actionMETHOD }
