import React from 'react'

function SvgCar(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M19 5l2 6v8a1 1 0 01-1 1h-1a1 1 0 01-1-1v-1H6v1a1 1 0 01-1 1H4a1 1 0 01-1-1v-8l2-6a1.3 1.3 0 011.5-1h11A1.4 1.4 0 0119 5zM5 10h14l-1.5-4.5h-11zm.4 4.5a1.5 1.5 0 002.1 0 1.5 1.5 0 000-2 1.5 1.5 0 00-2 0 1.5 1.5 0 000 2zm11 0a1.5 1.5 0 102.2-2 1.5 1.5 0 00-2.2 0 1.5 1.5 0 000 2z" />
    </svg>
  )
}

export default SvgCar
