import { useEffect, useState } from 'react'
import isServerSide from '../../../../modules/is-server-side'

const useFingerprintPiano = () => {
  const [fingerprint, setFingerprint] = useState()

  useEffect(() => {
    ;(() => {
      if (!isServerSide()) {
        window.tp = window.tp || []
        window.tp.push([
          'init',
          function () {
            const fingerprintPiano = window.tp.fingerprint.getFingerprint()

            if (fingerprint !== fingerprintPiano) {
              setFingerprint(fingerprintPiano)
            }
          },
        ])
      }
    })()
  }, [])

  return fingerprint
}

export default useFingerprintPiano
