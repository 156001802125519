import userSession from '../../../../../../topics/Profile/modules/user-session'
import checkUserExpiration from '../check-user-expiration'
import consoleLogger from '../../../../../../modules/console-logger'
import isServerSide from '../../../../../../modules/is-server-side'
import getParameterByName from '../../../../../../bundles/BundleAds/modules/get-parameter-by-name'
import routes from '../../../../../../topics/routes'

const addUserSession = ({ accessToken, forceUpdate, onError = () => {}, onSuccess = () => {} }) => {
  const [consoleLog] = consoleLogger('AutomaticLogin')
  const isAccessToken = accessToken && accessToken !== 'undefined'
  consoleLog('access token', accessToken, 'AddUserSession')
  const verificationUpdate =
    isAccessToken &&
    !isServerSide() &&
    window.history.length <= 1 &&
    getParameterByName('emailVerificationResult') === 'success'
  consoleLog('update user because of verification', verificationUpdate, 'AddUserSession')
  const notMatchToken = accessToken !== userSession.accessTokenV2
  consoleLog(
    "token doesn't match with access_token_v2",
    `${accessToken} != ${userSession.accessTokenV2}`,
    'AddUserSession',
  )
  const noStorageUserData = isAccessToken && !userSession.getData() && !userSession.getAppData()
  consoleLog('local storage no user data', noStorageUserData, 'AddUserSession')
  const expiredUserData = checkUserExpiration()
  consoleLog('user data expired', expiredUserData, 'AddUserSession')
  const isLocationProfile = !isServerSide() && routes.getOptions('account').path === window.location.pathname
  consoleLog('are we @ profile site', isLocationProfile, 'AddUserSession')

  const onGetUserData = () => {
    onSuccess()
    consoleLog('SUCCESS UPDATE - LOGIN DATA', '', 'AddUserSession')
    return true
  }

  if (notMatchToken && userSession.getData()) {
    consoleLog('CLEAR - LOGIN DATA', '', 'AddUserSession')
    userSession.clearUser()
  }

  if (noStorageUserData || verificationUpdate || expiredUserData || notMatchToken || isLocationProfile || forceUpdate) {
    consoleLog('START UPDATE - LOGIN DATA', '', 'AddUserSession')
    userSession.add({ pianoIdToken: accessToken, onError: onError, onSuccess: onGetUserData })
    return true
  }
  return false
}

export default addUserSession
