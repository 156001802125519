import React from 'react'

function SvgPause(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M6 19V5h4v14zm8-14h4v14h-4z" />
    </svg>
  )
}

export default SvgPause
