const includeJsFile = ({ link, async, onload = () => {}, parameter, idLoadOnce }) => {
  const script = document.createElement('script')

  script.src = link
  script.type = 'text/javascript'
  script.defer = true
  script.async = async

  if (onload !== undefined) {
    script.addEventListener('load', function (event) {
      onload(parameter)
    })
  }

  if (idLoadOnce) {
    script.id = idLoadOnce
    if (document.getElementById(idLoadOnce)) {
      return false
    }
  }
  document.getElementsByTagName('head').item(0).appendChild(script)
}

export default includeJsFile
