import React from 'react'

function SvgEnlargeFont(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12.71 20.3l-.93-3.72H6.57l-.94 3.72H2L7.07 3.7h4.3l5.09 16.6zM7.24 14h3.87L9.16 6.31zM19.43 4v2.7H22v1.97h-2.57v2.68h-2.09V8.67h-2.6V6.74h2.6V4z" />
    </svg>
  )
}

export default SvgEnlargeFont
