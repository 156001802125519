import React from 'react'

function SvgCheck(props) {
  return (
    <svg viewBox="0 0 128 128" {...props}>
      <path d="m54.3,97.2l-29.5,-29.5c-0.4,-0.4 -0.4,-1 0,-1.4l8.5,-8.5c0.4,-0.4 1,-0.4 1.4,0l20.3,20.3l38.2,-38.2c0.4,-0.4 1,-0.4 1.4,0l8.5,8.5c0.4,0.4 0.4,1 0,1.4l-47.4,47.4c-0.4,0.4 -1,0.4 -1.4,0z" />
    </svg>
  )
}

export default SvgCheck
