import React from 'react'

function SvgReduceFont(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M13.2 20.3l-.94-3.72H7.05l-.93 3.72H2.45L7.56 3.7h4.29l5.09 16.6zM7.73 14h3.86L9.65 6.31zM16.15 9V7.05h5.4V9z" />
    </svg>
  )
}

export default SvgReduceFont
