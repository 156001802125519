import React from 'react'

function SvgArrowDown(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M7.4 8.3l4.6 4.6 4.6-4.6L18 9.7l-6 6-6-6z" />
    </svg>
  )
}

export default SvgArrowDown
