import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledClamp = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.clamp ? props.clamp : '2')};
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const TruncateText = ({ text, truncateOptions }) => (
  <StyledClamp
    clamp={truncateOptions && truncateOptions.numberOfLines}
    ellipsis={truncateOptions && truncateOptions.ellipsis}
  >
    {text}
  </StyledClamp>
)

TruncateText.propTypes = {
  text: PropTypes.string,
  truncateOptions: PropTypes.shape({
    numberOfLines: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
    ellipsis: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
}

export default TruncateText
