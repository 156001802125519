import { useEffect, useState } from 'react'
import consoleLogger from '../../../../modules/console-logger'
import isServerSide from '../../../../modules/is-server-side'

const useExperiencePiano = () => {
  const [experienceData, setExperienceData] = useState()
  const [consoleLog] = consoleLogger('UseExperiencePiano')

  useEffect(() => {
    if (!isServerSide()) {
      window.tp = window.tp || []
      window.tp.push([
        'addHandler',
        'experienceExecute',
        (e) => {
          if (!experienceData) {
            consoleLog('execute EVENT', e, 'ExperienceExecute')
            setExperienceData(e)
          }
        },
      ])
    }
  }, [])

  return experienceData
}

export default useExperiencePiano
