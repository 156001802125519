import React from 'react'

function Video(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g transform="matrix(1.26057,0,0,1.26057,0,0)">
        <rect x="0" y="0" width="19.039" height="19.039" fillOpacity="0" fillRule="nonzero" />
      </g>
      <g transform="matrix(1.51241,0,0,1.51241,-3.02231,-3.02218)">
        <path
          d="M3.587,5.173L2,5.173L2,16.279C2.002,17.149 2.717,17.864 3.587,17.866L14.693,17.866L14.693,16.279L3.587,16.279L3.587,5.173ZM16.279,2L6.76,2C5.89,2.002 5.175,2.717 5.173,3.587L5.173,13.107C5.176,13.976 5.891,14.691 6.76,14.693L16.28,14.693C17.15,14.691 17.865,13.976 17.867,13.106L17.867,3.587C17.865,2.717 17.149,2.002 16.279,2ZM9.933,11.916L9.933,4.777L14.693,8.347L9.933,11.916Z"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default Video
