import { useReducer } from 'react'
import consoleLogger from '../../../console-logger'

const reducer = (state, action) => {
  const [consoleLog] = consoleLogger('UseStateAccess')
  const newState = {
    ...state,
  }
  const checkPlus = (statusPlus) => {
    const statusCriteria = Object.values(statusPlus)
    for (let i = 0; i < statusCriteria.length; i++) {
      switch (statusCriteria[i]) {
        case undefined:
          return undefined
        case true:
          return true
        default:
      }
    }

    return false
  }
  switch (action) {
    case 'givePlus':
      newState.access.isPlus = true
      newState.access.isStandard = true
      break
    case 'denyPlus':
      newState.access.isPlus = false
      break
    case 'giveStandard':
      newState.access.isStandard = true
      break
    case 'denyStandard':
      newState.access.isStandard = false
      break
    case 'truePlusUnveil':
      newState.statusPlus.unveil = true
      break
    case 'falsePlusUnveil':
      newState.statusPlus.unveil = false
      break
    case 'truePlusPiano':
      newState.statusPlus.piano = true
      break
    case 'falsePlusPiano':
      newState.statusPlus.piano = false
      break
    default:
      throw new Error()
  }
  consoleLog(`${action} - plus criteria`, newState.statusPlus, 'UseAccess')
  consoleLog(`${action} - ALL plus criteria fulfilled?`, checkPlus(newState.statusPlus), 'UseAccess')

  switch (checkPlus(newState.statusPlus)) {
    case true:
      newState.access.isPlus = true
      newState.access.isStandard = true
      break
    case false:
      newState.access.isPlus = false
      break
    case undefined:
    default:
  }

  return newState
}
const initialState = {
  access: {
    isPlus: undefined,
    isStandard: undefined,
  },
  statusPlus: {
    unveil: undefined,
    piano: undefined,
  },
}

const useStateAccess = () => {
  return useReducer(reducer, initialState)
}

export default useStateAccess
