import { useEffect, useState } from 'react'
import isServerSide from '../../../../modules/is-server-side'
import consoleLogger from '../../../../modules/console-logger'

const useUserCustomFields = (dependency) => {
  const [userData, setUserData] = useState()
  const [consoleLog] = consoleLogger('UseUserCustomFields')

  useEffect(() => {
    if (!isServerSide() && window.tp && window.tp.pianoId) {
      window.tp.pianoId.loadExtendedUser({
        extendedUserLoaded: function (data) {
          consoleLog('state', userData, 'UseUserData')
          consoleLog('extendedUserLoaded data', data, 'UseUserData')
          setUserData(data)
        },
        formName: 'MyAccountFields',
      })
    }
  }, [dependency])

  return userData
}

export default useUserCustomFields
