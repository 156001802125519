import React from 'react'

function SvgPrint(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M18.3,8.444c0.717,-0.011 1.407,0.277 1.899,0.792c0.521,0.486 0.812,1.167 0.801,1.875l0,5.333l-3.6,0l0,3.556l-10.8,-0l-0,-3.556l-3.6,0l-0,-5.333c-0.011,-0.708 0.28,-1.389 0.801,-1.875c0.492,-0.515 1.182,-0.803 1.899,-0.792l12.6,0Zm-0.9,-4.444l0,3.556l-10.8,-0l-0,-3.556l10.8,-0Zm-1.8,14.222l0,-4.444l-7.2,-0l-0,4.444l7.2,0Zm2.07,-6.453c0.169,0.159 0.397,0.243 0.63,0.231c0.244,0.009 0.481,-0.081 0.657,-0.249c0.171,-0.169 0.259,-0.402 0.243,-0.64c-0.027,-0.477 -0.417,-0.862 -0.9,-0.889c-0.24,-0.002 -0.47,0.099 -0.63,0.276c-0.167,0.162 -0.264,0.382 -0.27,0.613c-0.013,0.239 0.079,0.472 0.252,0.64l0.018,0.018Z" />
    </svg>
  )
}

export default SvgPrint
