import React from 'react'
import PropTypes from 'prop-types'
import TruncateText from '../../components/TruncateText'

const truncate = (ComponentToTruncate) => ({ truncateOptions, children, joinChildren = false, ...props }) => (
  <ComponentToTruncate {...props}>
    {typeof children === 'string' && truncateOptions && (
      <TruncateText text={children} truncateOptions={truncateOptions} />
    )}
    {typeof children !== 'string' && truncateOptions && joinChildren && (
      <TruncateText text={children.props.children.join(' ')} truncateOptions={truncateOptions} />
    )}
    {!truncateOptions && children}
  </ComponentToTruncate>
)

truncate.propTypes = {
  ComponentToTruncate: PropTypes.node,
  children: PropTypes.string,
  truncateOptions: TruncateText.propTypes.truncateOptions,
}

export default truncate
