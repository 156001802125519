import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrapper = styled.li`
  color: ${(props) => (props.inherit ? 'inherit' : props.theme.color.textPrimary)};
  font-family: ${(props) => (props.inherit ? 'inherit' : props.theme.font.primary)};
  font-size: ${(props) => (props.inherit ? 'inherit' : props.theme.fontSize.base)};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => (props.inherit ? 'inherit' : props.theme.tablet.fontSize.base)};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => (props.inherit ? 'inherit' : props.theme.mobile.fontSize.base)};
  }
  line-height: ${(props) => (props.inherit ? 'inherit' : props.theme.lineHeight.text)};
  list-style: none;
`

const ListType = styled.div``

const ListTypeShape = styled(ListType)`
  background-color: ${(props) => props.theme.color.textPrimary};
`

const ListTypeSquare = styled(ListTypeShape)`
  height: 0.4em;
  width: 0.4em;
  display: inline-block;
  vertical-align: 0.2em;
  margin-right: 0.5em;
`

class ListItem extends Component {
  getListStyle = (listStyle, index) => {
    switch (listStyle) {
      case 'square':
        return <ListTypeSquare />
      case 'index':
        return <ListType>{index}</ListType>
      default:
        return
    }
  }

  render() {
    const { children, listStyle, index, inherit, ...props } = this.props

    return (
      <Wrapper inherit={inherit} {...props}>
        {this.getListStyle(listStyle, index)}
        {children}
      </Wrapper>
    )
  }
}

ListItem.propTypes = {
  children: PropTypes.node,
  listStyle: PropTypes.oneOf(['square', 'index']),
  index: PropTypes.node,
  inherit: PropTypes.bool,
}

ListItem.defaultProps = {
  inherit: false,
  listStyle: 'square',
}

export default ListItem
