import React from 'react'
import OneSignal from 'react-onesignal'

export default async function runOneSignal() {
  await OneSignal.init({
    appId: '4dd7c402-c5b6-489e-941e-fe0cc6fd3368',
    allowLocalhostAsSecureOrigin: true,
    notifyButton: {
      enable: false /* Set to false to hide */,
      text: {
        'tip.state.unsubscribed': 'Nichts mehr verpassen',
        'tip.state.subscribed': 'Sie bekommen die Benachrichtigung',
        'tip.state.blocked': 'Sie haben die Benachritigungen blockiert',
        'message.prenotify': 'Klicken um die Benachrichtiungen zu aktivieren',
        'message.action.subscribed': 'Danke fürs aktivieren der Benachrichtigungen',
        'message.action.resubscribed': 'Sie haben die Benachrichtigungen aktiviert',
        'message.action.unsubscribed': 'Sie werden keine Benachrichtigungen mehr bekommen',
        'dialog.main.button.subscribe': 'Aktivieren',
        'dialog.main.button.unsubscribe': 'Deaktivieren',
      },
    },
    welcomeNotification: {
      title: 'Stol.it: Nachrichten für Südtirol',
      message: 'Danke für die Aktivierung der Benachrichtigungen',
    },
    promptOptions: {
      actionMessage: 'Wir möchten Sie gerne über die neuesten Nachrichten und Aktualisierungen benachrichtigen.',
      acceptButtonText: 'Aktivieren',
      cancelButtonText: 'Abbrechen',
      showCredit: 'false',
      slidedown: {
        enabled: true,
        autoPrompt: true,
        timeDelay: 30,
      },
    },
  })
  // OneSignal.showSlidedownPrompt();
}
