import { css, keyframes } from 'styled-components'

const fade = keyframes`
  10%,
  90% {
    opacity: 1;
  }
  
  50% {
    opacity: .6;
  }
`

const skeletonFade = css`
  animation: ${fade} 1.4s ease-in infinite;
`

export default skeletonFade
