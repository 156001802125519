import React from 'react'

function SvgGps(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M21 11h2v2h-2a9 9 0 01-8 8v2h-2v-2a8.7 8.7 0 01-5.4-2.6A8.7 8.7 0 013.1 13H1v-2h2a8.6 8.6 0 012.6-5.4A8.7 8.7 0 0111 3.1V1h2v2a8.7 8.7 0 015.4 2.6A8.7 8.7 0 0121 11zM7 17a6.8 6.8 0 005 2 7 7 0 007-7 7 7 0 00-7-7 7 7 0 00-7 7 6.8 6.8 0 002 5zm2.2-7.8a4 4 0 015.6 0 4 4 0 010 5.6 4 4 0 01-5.6 0 4 4 0 010-5.6z" />
    </svg>
  )
}

export default SvgGps
