import React from 'react'

function SvgPerson(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M6.7 15.1A14 14 0 0112 14a14 14 0 015.3 1.1c1.8.8 2.7 1.7 2.7 2.9v2H4v-2c0-1.2.9-2.2 2.7-2.9zm8.1-4.3a4 4 0 01-5.6 0A3.8 3.8 0 018 8a4 4 0 011.2-2.8 3.9 3.9 0 015.6 0A4 4 0 0116 8a3.8 3.8 0 01-1.2 2.8z" />
    </svg>
  )
}

export default SvgPerson
