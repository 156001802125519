import React from 'react'

function SvgFacebook(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M16.154,4.106l0,2.549l-1.514,-0c-0.4,-0.059 -0.807,0.059 -1.113,0.323c-0.214,0.3 -0.316,0.666 -0.287,1.033l-0,1.838l2.814,0l-0.373,2.872l-2.441,-0l-0,7.279l-2.943,0l-0,-7.279l-2.456,-0l0,-2.872l2.456,0l-0,-2.089c-0.068,-1.019 0.288,-2.022 0.983,-2.771c0.718,-0.683 1.688,-1.039 2.678,-0.983c0.733,-0.018 1.468,0.016 2.196,0.1Z" />
    </svg>
  )
}

export default SvgFacebook
