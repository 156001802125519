import isServerSide from '../../../../modules/is-server-side'

const getToken = () => {
  if (!isServerSide()) {
    if (!window.tp || !window.tp.pianoId) {
      return false
    }
    const pianoId = window.tp.pianoId

    return pianoId.getToken()
  }
  return false
}

export default getToken
