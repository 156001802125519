import React from 'react'

function SvgShare(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M18 16.1a2.76 2.76 0 012.06.87A2.91 2.91 0 1116 21.09a2.82 2.82 0 01-.91-2.09 3.23 3.23 0 01.05-.66l-7.08-4.11A3 3 0 016 15a2.86 2.86 0 01-2.11-.89A2.88 2.88 0 013 12a2.91 2.91 0 01.89-2.11A2.9 2.9 0 016 9a3 3 0 012.06.8l7-4.08A4 4 0 0115 5a2.9 2.9 0 01.89-2.11 2.95 2.95 0 014.22 0 2.95 2.95 0 010 4.22A2.9 2.9 0 0118 8a3 3 0 01-2.06-.84l-7 4.12A4.12 4.12 0 019 12a4.21 4.21 0 01-.09.71L16 16.85a2.87 2.87 0 012-.75z" />
    </svg>
  )
}

export default SvgShare
