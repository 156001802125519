import React from 'react'

const SvgClock = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M12.02,3.185c-4.826,0 -8.775,3.949 -8.775,8.775c0,4.826 3.949,8.775 8.775,8.775c4.826,0 8.775,-3.949 8.775,-8.775c0,-4.826 -3.949,-8.775 -8.775,-8.775Zm0,15.795c-3.861,0 -7.02,-3.159 -7.02,-7.02c0,-3.861 3.159,-7.02 7.02,-7.02c3.861,0 7.02,3.159 7.02,7.02c0,3.861 -3.159,7.02 -7.02,7.02Z" />
    <path d="M12.459,7.573l-1.316,0l0,5.264l4.562,2.721l0.702,-1.053l-3.948,-2.37l0,-4.562Z" />
  </svg>
)

export default SvgClock
