import React from 'react'

function SvgNotification(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 46" {...props}>
      <path
        d="m20.2 10.65v1c-7.66 0.78-8.51 6.89-9.29 12.3-0.54 3.63-1 7.43-3.48 9.98-0.46 0.54-0.62 1.24-0.38 1.93 0.31 0.62 0.93 1.08 1.62 1.08h11.1c0.08 1.24 1 2.17 2.24 2.17 1.16 0 2.17-1 2.24-2.17h11.14c0.7 0 1.4-0.46 1.63-1.08 0.31-0.62 0.15-1.39-0.39-1.93-2.48-2.55-3-6.35-3.48-9.98-0.77-5.42-1.63-11.53-9.37-12.3v-1c0-1-0.72-1.78-1.74-1.78s-1.84 0.77-1.84 1.78zm1.78 4.49c6 0 6.73 3 7.58 9.29 0.39 2.94 0.85 6.11 2.32 8.98h-19.73c1.47-2.86 1.93-6 2.32-8.98 0.85-6.27 1.55-9.29 7.5-9.29"
        fill="#555"
      />
      <rect x="22" width="24" height="24" rx="4" fill="#7e7e7e" />
      <text
        x="34"
        y="18"
        style={{
          'font-size': 18,
          'font-family': 'Helvetica,Arial',
          fill: '#fff',
          'font-weight': 'bold',
          'text-anchor': 'middle',
        }}
      >
        2
      </text>
    </svg>
  )
}

export default SvgNotification
