const menuParseAndEnrich = (response) => {
  let mainNavigation = []
  response.more.forEach((menuItem) => {
    if (menuItem.web === false) {
      return false
    }
    let currentMenuItem = []
    currentMenuItem.push({
      name: menuItem.name,
      url: menuItem.url,
      type: 'big',
    })
    if (menuItem.submenu) {
      menuItem.submenu.map((subMenuItem) => {
        if (subMenuItem.name !== 'Alle' && subMenuItem.web === undefined) {
          currentMenuItem.push({
            name: subMenuItem.name,
            url: subMenuItem.url,
            type: 'default',
          })
        }
        return null
      })
    }
    mainNavigation.push(currentMenuItem)
    return null
  })

  let specialNavigation = []
  response.side.forEach((menuItem) => {
    if (menuItem.web === undefined) {
      specialNavigation.push({
        name: menuItem.name,
        url: menuItem.url,
        icon: menuItem.icon,
      })
    }
    return null
  })

  return {
    responseData: response,
    mainNavigation,
    specialNavigation,
  }
}

export default menuParseAndEnrich
