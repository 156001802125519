import React from 'react'

function SvgWhatsApp(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M20,11.787c0,4.302 -3.52,7.786 -7.858,7.786c-1.369,0 -2.666,-0.355 -3.804,-0.977l-4.338,1.404l1.422,-4.178c-0.711,-1.173 -1.12,-2.56 -1.12,-4.018c-0.018,-4.32 3.502,-7.804 7.84,-7.804c4.338,0 7.858,3.484 7.858,7.787Zm-7.858,-6.543c-3.644,0 -6.595,2.934 -6.595,6.543c-0,1.44 0.462,2.755 1.262,3.84l-0.818,2.435l2.542,-0.8c1.049,0.694 2.294,1.085 3.627,1.085c3.627,-0 6.596,-2.934 6.596,-6.56c-0,-3.627 -2.969,-6.543 -6.614,-6.543Zm3.965,8.338c-0.054,-0.071 -0.178,-0.124 -0.374,-0.231c-0.195,-0.089 -1.137,-0.551 -1.315,-0.622c-0.178,-0.071 -0.302,-0.089 -0.427,0.089c-0.124,0.195 -0.498,0.622 -0.604,0.746c-0.107,0.125 -0.231,0.143 -0.409,0.054c-0.196,-0.089 -0.818,-0.302 -1.547,-0.942c-0.569,-0.498 -0.96,-1.138 -1.067,-1.316c-0.106,-0.196 -0.017,-0.302 0.089,-0.391c0.089,-0.089 0.196,-0.231 0.285,-0.338c0.089,-0.107 0.124,-0.195 0.195,-0.32c0.071,-0.124 0.036,-0.231 -0.017,-0.338c-0.054,-0.089 -0.427,-1.031 -0.587,-1.422c-0.16,-0.373 -0.32,-0.32 -0.427,-0.32c-0.106,0 -0.249,-0.018 -0.373,-0.018c-0.125,0 -0.338,0.054 -0.516,0.231c-0.177,0.196 -0.675,0.658 -0.675,1.6c-0,0.943 0.693,1.849 0.782,1.974c0.089,0.124 1.333,2.115 3.289,2.88c1.955,0.764 1.955,0.515 2.311,0.48c0.356,-0.036 1.138,-0.462 1.298,-0.907c0.142,-0.427 0.142,-0.8 0.089,-0.889Z" />
    </svg>
  )
}

export default SvgWhatsApp
