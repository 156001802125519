import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'
import theme from '../../../config/themes'

// Create a theme instance.
const muiTheme = createTheme({
  palette: {
    primary: {
      main: theme.default.color.stol,
    },
    secondary: {
      main: theme.default.color.stolDark,
    },
    error: {
      main: red.A400,
    },
    text: {
      primary: theme.default.color.textPrimary,
      secondary: theme.default.color.textSecondary,
    },
    background: {
      paper: theme.default.color.background,
    },
  },
  typography: {
    fontFamily: theme.default.font.primary,
  },
})

export default muiTheme
