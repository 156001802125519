import React from 'react'

function SvgGooglePlus(props) {
  return (
    <svg viewBox="0 0 34 24" {...props}>
      <path d="M20.71 12.23a10.32 10.32 0 01-1.16 5 8.42 8.42 0 01-3.32 3.4 9.81 9.81 0 01-4.94 1.23A9.6 9.6 0 017.47 21a9.81 9.81 0 01-3.13-2 9.71 9.71 0 01-2.09-3.13 9.74 9.74 0 010-7.64A9.63 9.63 0 017.47 3a9.6 9.6 0 013.82-.78 9.29 9.29 0 016.57 2.57L15.2 7.31a5.41 5.41 0 00-3.91-1.51 5.9 5.9 0 00-3 .83A6.1 6.1 0 006 8.89a6.25 6.25 0 002.22 8.48 5.9 5.9 0 003 .83 6.33 6.33 0 002-.31 5 5 0 001.53-.77 5.8 5.8 0 001.05-1.05 4.75 4.75 0 00.77-1.07 5.42 5.42 0 00.29-1h-5.57v-3.41h9.26a8.59 8.59 0 01.16 1.64zm11.62-1.64v2.82h-2.8v2.79h-2.82v-2.79h-2.79v-2.82h2.79v-2.8h2.82v2.8z" />
    </svg>
  )
}

export default SvgGooglePlus
