import React, { useContext } from 'react'

const WidgetContext = React.createContext(false)

export const WidgetProvider = WidgetContext.Provider
export const WidgetConsumer = WidgetContext.Consumer
export default WidgetContext

const useWidgetContext = () => useContext(WidgetContext)

export { useWidgetContext }
