import Config from '../../config'
import options from '../../config/options'
import isServerSide from '../is-server-side'
import axios from 'axios'
import envApiPrefix from '../env-api-prefix'

const apiConnector = async ({
  accessType = 'Bearer',
  access,
  version,
  userAgent,
  location,
  data,
  method = 'get',
  signal,
  external = false,
  allowAbortSignal = false,
}) => {
  let fetchPath = ''

  if (external) {
    fetchPath = location
  } else {
    fetchPath = Config.restUrl(location, version)
  }

  const optionsFetch = {
    method,
    timeout: options.Api.timeout,
    url: fetchPath,
  }

  if (!userAgent && isServerSide()) {
    userAgent = 'bot'
  }
  if (userAgent) {
    optionsFetch.headers = optionsFetch.headers || {}
    optionsFetch.headers['User-Agent'] = userAgent
  }
  if (envApiPrefix() && isServerSide()) {
    optionsFetch.headers = optionsFetch.headers || {}
    optionsFetch.headers['Host'] = 'www.stol.it'
    optionsFetch.headers['X-Forwarded-Proto'] = 'https'
  }
  if (access) {
    optionsFetch.headers = optionsFetch.headers || {}
    optionsFetch.headers['Authorization'] = `${accessType} ${access}`
  }
  if (data) {
    optionsFetch['data'] = data
  }
  if (allowAbortSignal) {
    optionsFetch.signal = signal
  }
  try {
    const response = await axios(optionsFetch)

    return response.data
  } catch (error) {
    throw error
  }
}

export default apiConnector
