import userSession from '../../../../../../topics/Profile/modules/user-session'
import consoleLogger from '../../../../../../modules/console-logger'
import isServerSide from '../../../../../../modules/is-server-side'
import routes from '../../../../../../topics/routes'

const onLoginSuccess = () => {
  const [consoleLog] = consoleLogger('OnLoginSuccess')
  if (!isServerSide()) {
    window.tp.push([
      'addHandler',
      'loginSuccess',
      function (data) {
        const isLogout = window.location.pathname.toLowerCase() === '/abmelden'
        const isLoginPage = window.location.pathname.toLowerCase() === '/anmelden'
        if (isLoginPage) {
          let accountPage = routes.getOptions('account').path
          if (data.registration) {
            accountPage = accountPage.concat('?register=1')
          }
          window.location.href = accountPage
        }
        if (data.user_token) {
          if (!isLogout) {
            userSession.add({ pianoIdToken: data.user_token })
          }
          if (!isLoginPage) {
            consoleLog('EXECUTE', '', 'ExperienceExecute')
            window.tp.experience.execute()
          }
        }
      },
    ])
  }
}

export default onLoginSuccess
