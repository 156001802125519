import React, { useContext } from 'react'
import useAccess from '../../modules/use-access'
import useUserData from '../../vendor/Piano/modules/use-user-data'
import useExperiencePiano from '../../vendor/Piano/modules/use-experience-piano'
import useUserCustomFields from '../../vendor/Piano/modules/use-user-custom-fields'

const ContextUser = React.createContext({ access: {}, profile: {} })

const UserProvider = ({ children, ...props }) => {
  const experienceData = useExperiencePiano()
  const extendedUserData = useUserCustomFields(experienceData)
  const access = useAccess(experienceData)
  const profile = useUserData(extendedUserData, experienceData)

  return (
    <ContextUser.Provider value={{ access, profile }} {...props}>
      {children}
    </ContextUser.Provider>
  )
}

const useUserContext = () => useContext(ContextUser)

export default ContextUser

export { UserProvider, useUserContext }
