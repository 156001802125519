import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ItemList from './components/ItemList'

const Wrapper = styled.ul`
  color: ${(props) => props.theme.color.textPrimary};
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.theme.fontSize.base};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.base};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.base};
  }
  line-height: ${(props) => props.theme.lineHeight.text};
  margin: 0;
  padding: 0;
`

const List = ({ items, ...props }) => (
  <Wrapper {...props}>
    {items.map((content, index) => (
      <ItemList key={index + content + 'ListItem'} inherit>
        {content}
      </ItemList>
    ))}
  </Wrapper>
)

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node),
}

List.defaultProps = {
  items: [],
}

export default List
