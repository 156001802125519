import { matchPath } from 'react-router-dom'
import generatePathByRouteName from './modules/generate-path-by-route-name'
import hasItems from '../../modules/has-items'
import createQueryKey from '../../modules/create-query-key'
import RouteQuery from '../RouteQuery'
import RouteAds from '../RouteAds'
import RouteScreen from '../RouteScreen'
import config from '../../config'

class RoutesConfig {
  constructor() {
    this.routes = {}
  }
  addNew({ name, path, exact, topic, api, screen, query, ads, sPlus, showLayout = true, ...props }) {
    this.routes[name] = {
      ...props,
    }
    if (path) {
      this.routes[name].path = path
    }
    if (api) {
      this.routes[name].api = api
    }
    if (exact) {
      this.routes[name].exact = exact
    }
    if (topic) {
      this.routes[name].topic = topic
    }
    if (screen) {
      this.routes[name].screen = screen
    }
    if (showLayout) {
      this.routes[name].showLayout = showLayout
    }

    if (query instanceof RouteQuery || (typeof query === 'boolean' && query === true)) {
      this.routes[name].query = query
    }
    if (ads instanceof RouteAds) {
      this.routes[name].ads = ads
    }
    if (screen instanceof RouteScreen) {
      this.routes[name].screen = screen
    }
  }
  getQueryKey(props) {
    let query
    let view
    if (typeof props === 'string') {
      view = props
      query = this.prepareQueryFetchVariables({ view })
    }

    if (typeof props === 'object') {
      view = props.view
      if (!view) {
        view = this.getFirstRouteName()
      }
      query = this.prepareQueryFetchVariables(props)
    }
    if (!query) {
      return false
    }
    return createQueryKey(view, query.fetchVariables)
  }
  prepareQueryFetchVariables({ view, location, match, customApi }) {
    if (!view) {
      view = this.getFirstRouteName()
    }
    const routeOptions = this.getOptions(view)

    let query = routeOptions && routeOptions.query
    if (!query) {
      return false
    }
    if (query === true) {
      query = new RouteQuery({})
    }

    let queryLocation = customApi
    if (query.useLocationPathname && location && location.pathname) {
      queryLocation = location.pathname
    }
    const apiOption = routeOptions && routeOptions.api
    const matchSlug = match && match.params && match.params.slug
    if (apiOption && matchSlug && routeOptions) {
      queryLocation = generatePathByRouteName(routeOptions, { params: { slug: match.params.slug }, type: 'api' })
    }

    if (queryLocation) {
      query.fetchVariables = query.fetchVariables || {}
      query.fetchVariables.location = queryLocation
    }

    return query
  }
  getAPIUrl(view) {
    const query = this.prepareQueryFetchVariables({ view: view })
    const fetchVariables = query.fetchVariables || {}

    return config.restUrl(query.location || view, fetchVariables.version)
  }
  addRoute(route) {
    if (!(route instanceof RoutesConfig)) {
      return false
    }
    this.routes = {
      ...this.routes,
      ...route.toObject(),
    }
  }
  map(callback) {
    return this.getRouteNames().map(callback)
  }
  find(callback) {
    return this.getRouteNames().find(callback)
  }
  set(object) {
    this.routes = {
      ...this.routes,
      ...object,
    }
  }
  getRoute(name) {
    if (!name) {
      return false
    }

    const newRoutesObject = new RoutesConfig()
    const newObject = {}
    newObject[name] = this.routes[name]

    newRoutesObject.set(newObject)

    return newRoutesObject
  }
  firstEntry() {
    if (!hasItems(this.routes)) {
      return false
    }
    return {
      name: Object.keys(this.routes)[0],
      options: Object.values(this.routes)[0],
    }
  }
  getOptions(name) {
    if (!name && this.firstEntry()) {
      return this.firstEntry().options
    }
    return this.routes[name]
  }
  toObject() {
    return this.routes
  }
  getFirstRouteName() {
    return this.firstEntry().name
  }
  getRouteNames() {
    return Object.keys(this.routes)
  }
  generatePathByRouteName(name, params = {}, type = 'path') {
    const routeOptions = this.getOptions(name)
    if (!name && !routeOptions) {
      return false
    }
    return generatePathByRouteName(routeOptions, { params, type })
  }
  currentRouteName(location) {
    return this.find((key) => matchPath(location.pathname, { ...this.getOptions(key) }))
  }
}

export default RoutesConfig
