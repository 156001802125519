import { useCookies } from 'react-cookie'
import userSession from '../../../../topics/Profile/modules/user-session'
import isStolApp from '../../../../modules/is-stolapp'
import React, { useEffect } from 'react'
import loginPiano from '../../../../vendor/Piano/modules/login-piano'
import isUserValidPiano from '../../../../vendor/Piano/modules/is-user-valid-piano'
import logoutPiano from '../../../../vendor/Piano/modules/logout-piano'
import addUserSession from './modules/add-user-session'
import { useLocation } from 'react-router'
import useReadyPiano from '../../../../vendor/Piano/modules/use-ready-piano'
import consoleLogger from '../../../../modules/console-logger'
import routes from '../../../../topics/routes'

const AutomaticLogin = ({ onReady }) => {
  const [cookies, removeCookie] = useCookies(['stol_jwt_token'])
  const isReadyPiano = useReadyPiano()
  const location = useLocation()
  const appAccessToken = cookies['stol_jwt_token']
  const [consoleLog] = consoleLogger('AutomaticLogin')

  if (isStolApp() && !!appAccessToken) {
    userSession.storeAccessToken(appAccessToken)
  }
  const accessToken = userSession.getAccessToken()

  useEffect(() => {
    if (isReadyPiano) {
      let loggedInPiano = isUserValidPiano()

      if (routes.currentRouteName(location) === 'profile.logout') {
        return
      }
      consoleLog('piano is ready', '')
      consoleLog('are we logged in piano? ', loggedInPiano)
      consoleLog('app access token', appAccessToken)

      if (isStolApp()) {
        if (loggedInPiano && !appAccessToken) {
          consoleLog('piano logout', '')
          logoutPiano()
          return
        }

        consoleLog('cms/piano access token', accessToken)

        if (accessToken && accessToken !== 'undefined' && loggedInPiano === false) {
          consoleLog('PIANO LOGIN WITH TOKEN: ', accessToken)
          loggedInPiano = loginPiano({ token: accessToken, createSession: false })
          consoleLog('PIANO LOGIN SUCCEED: ', loggedInPiano)
        }
      }

      if ((!isStolApp() && loggedInPiano) || (appAccessToken && loggedInPiano)) {
        addUserSession({
          accessToken,
        })
      }

      if (loggedInPiano === false) {
        consoleLog('REMOVE app token cookie, cms token + user data')
        removeCookie('stol_jwt_token')
        userSession.clear()
      }

      onReady()
    }
  }, [isReadyPiano])
  return true
}

AutomaticLogin.defaultProps = {
  onReady: () => {},
}

export default AutomaticLogin
