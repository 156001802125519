import React from 'react'

function SvgDolomiten(props) {
  return (
    <svg width={53} height={81} viewBox="0 0 53 81" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>dolomiten</title>
      <g id="dolomiten" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path
          d="M53,67.8562619 L53,70.9474655 C36.7317151,67.6275805 27.8245563,79.7032064 25.5019738,80.9195781 C23.4141622,81.9677756 14.2345436,72.4459074 4.27759004,71.1823757 C7.33746292,69.2541551 7.13623047,66.737279 7.13623047,65.9106445 L7.13623047,33.7675781 C7.13623047,33.3849594 7.67587842,30.8436808 4.61522036,29.6335378 C13.214586,24.244842 21.2195652,18.7333522 21.8539961,18.2973448 C16.0310509,15.9704889 9.45197091,14.561918 6.07488258,14.8831398 C4.12918974,15.051314 2.81871301,16.2614571 0.974309267,16.9804244 C0.684575346,15.9313372 -0.0456483574,12.6861962 0.00224803605,12.6861962 C1.13370218,12.1531994 15.2301604,0.326720541 15.3369458,0.0250745897 C16.5210075,-0.389577367 38.1238513,4.26769375 50.7433732,19.2850351 C50.2636241,19.4994796 49.7909416,19.4149475 49.3520222,19.3304155 C47.9331897,19.0474555 46.8590542,18.7716141 46.8590542,29.0827447 C46.8590542,35.7963692 46.8661209,48.4485928 46.8661209,63.4748322 C46.8661209,65.821264 50.1050163,67.7904159 53,67.8562619 Z M23,59.9519043 C23,63.7656152 31.8087693,67.6083458 32.9811513,68 C32.9811513,60.8080477 33.0273498,26.0769001 32.9750925,25.9695976 C32.134431,24.2 30.0820052,22.123696 26.0687932,20 C26.0687932,20 23,20.2628912 23,22.8157973 L23,59.9519043 Z"
          id="Combined-Shape"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default SvgDolomiten
