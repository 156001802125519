import useUnveil from './modules/use-unveil'
import { useEffect } from 'react'
import consoleLogger from '../console-logger'
import useStateAccess from './modules/use-state-access'

const useAccess = (experienceData) => {
  const unveil = useUnveil()

  const [consoleLog] = consoleLogger('UseAccess')
  const [state, dispatch] = useStateAccess()

  consoleLog('access', state.access)
  useEffect(() => {
    consoleLog('unveil', unveil)
    if (unveil) {
      dispatch('truePlusUnveil')
    } else {
      dispatch('falsePlusUnveil')
    }
    consoleLog('unveil isPlus', state.access.isPlus)
  }, [unveil])

  useEffect(() => {
    consoleLog('piano experience data', experienceData)
    if (experienceData && experienceData.accessList) {
      consoleLog('piano experience access list', experienceData.accessList)
      const timestampNow = Math.floor(new Date().getTime() / 1000)
      const hasAccesses = experienceData.accessList.map(
        (access) => access.expireDate >= timestampNow || access.expireDate === -1,
      )
      consoleLog('piano experience accesses', hasAccesses)
      const accessResponse = hasAccesses.includes(true)
      consoleLog('piano experience accessResponse', accessResponse)
      if (accessResponse) {
        dispatch('truePlusPiano')
      } else {
        dispatch('falsePlusPiano')
      }
    }
  }, [experienceData])

  useEffect(() => {
    if (experienceData && experienceData.user.uid !== 'anon') {
      dispatch('giveStandard')
    }
    if (experienceData && experienceData.user.uid === 'anon') {
      dispatch('denyStandard')
    }
  }, [experienceData])

  return state.access
}

export default useAccess
